import { useEffect, useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { fetchSubFolderById } from '../../api';

interface SubFolderDialogItemProps {
  onSaveClick: (name: string) => void;
  onClose: () => void;
  subFolderId?: string;
}

const SubFolderDialogItem: React.FC<SubFolderDialogItemProps> = ({
  onSaveClick,
  onClose,
  subFolderId,
}) => {
  const [title, setTitle] = useState<string>('');

  const handleSaveClick = () => {
    if (title.trim()) {
      onSaveClick(title);
    }
  };

  // fetch subfolder if subFolderId is provided
  useEffect(() => {
    const loadSubFolder = async () => {
      try {
        if (!subFolderId) return;

        const res = await fetchSubFolderById(subFolderId);
        setTitle(res.data.title);
      } catch (err) {
        console.error('Error fetching exam:', err);
      }
    };

    loadSubFolder();
  }, [subFolderId]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
      <TextField
        label="Folder Name"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveClick}
          sx={{ mx: 1 }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default SubFolderDialogItem;
