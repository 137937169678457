import React from 'react';
import { Paper, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';

interface CourseDetailsProps {
  courseContent: any;
}

const CourseDetails: React.FC<CourseDetailsProps> = ({ courseContent }) => (
  <Paper
    elevation={2}
    sx={{
      padding: 3,
      py: 1.5,
      backgroundColor: 'background.paperDarker',
      color: 'background.default',
      borderRadius: '0px',
    }}
  >
    <Typography variant="h3" sx={{ mb: 1, color: 'background.default' }}>
      {courseContent.title}
    </Typography>
    <Typography variant="body2" paragraph sx={{ color: 'background.default' }}>
      {courseContent.description}
    </Typography>
    <Typography variant="subtitle2">
      Được tạo bởi {courseContent.createdBy.fullName}
    </Typography>
    <Typography variant="subtitle2" sx={{ mb: 1 }}>
      Cập nhật lần cuối:{' '}
      {formatDistanceToNow(new Date(courseContent.lastUpdatedAt), {
        addSuffix: true,
        locale: vi,
      })}
    </Typography>
  </Paper>
);

export default CourseDetails;
