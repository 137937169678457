import { ButtonProps } from '@mui/material';
import { Theme } from '@mui/material';

import Digital from '../assets/Fonts/digital.ttf';
import ArialRounded from '../assets/Fonts/ARLRDBD.ttf';
import SegoeUISemiBold from '../assets/Fonts/SegoeUI-SemiBold.ttf';
import ArialUnicodeMS from '../assets/Fonts/ArialUnicodeMS.ttf';
import _SegoeUIBold from '../assets/Fonts/Segoe-UI-Bold.ttf';
import _SegoeUINormal from '../assets/Fonts/SVN-Segoe-UI.ttf';
import HandWriting from '../assets/Fonts/Merienda-VariableFont_wght.ttf';
import Signature from '../assets/Fonts/BrothersideSignature-w13o6.otf';
import OswaldSemiBold from '../assets/Fonts/Oswald-SemiBold.ttf';
import _MontserratBold from '../assets/Fonts/Montserrat-Bold.ttf';
import { alpha } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    gradient: true;
    gradient2: true;
    gradient3: true;
  }
}

const components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Digital';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${Digital}) format('truetype');
      }
      @font-face {
        font-family: 'OswaldSemiBold';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${OswaldSemiBold}) format('truetype');
      }
      @font-face {
        font-family: 'ArialRounded';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${ArialRounded}) format('truetype');
      }
      @font-face {
        font-family: 'SegoeUISemiBold';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${SegoeUISemiBold}) format('truetype');
      }
      @font-face {
        font-family: '_SegoeUIBold';
        font-style: normal;
        font-display: swap;
        font-weight: 800;
        src: url(${_SegoeUIBold}) format('truetype');
      }
      @font-face {
        font-family: '_MontserratBold';
        font-style: normal;
        font-display: swap;
        font-weight: 800;
        src: url(${_MontserratBold}) format('truetype');
      }
      @font-face {
        font-family: '_SegoeUINormal';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${_SegoeUINormal}) format('truetype');
      }
      @font-face {
        font-family: 'ArialUnicodeMS';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${ArialUnicodeMS}) format('truetype');
      }
      @font-face {
        font-family: 'HandWriting';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${HandWriting}) format('truetype');
      }
      @font-face {
        font-family: 'Signature';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: url(${Signature}) format('opentype');
      }

      /* width */
      ::-webkit-scrollbar {
        width: 8px;
        border-radius: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #aaaaaa;
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `,
  },
};

export default components;
