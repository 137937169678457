import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  Button,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
  Theme,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CloseIcon from '@mui/icons-material/Close';

// RHF
import FormProvider from '../RHF/FormProvider';
import RHFInput from '../RHF/RHFTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

// App Component
import { StyledPaper } from './style';

// Data
import { ChangePasswordData } from '../../model/Student';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MODAL, appActions } from '../../redux/slices/appSlice';
import { changePassword } from '../../api';
import { authActions } from '../../redux/slices/authSlice';

type PropsType = {
  user: { avatar: string; fullName: string }; // Adjusted to match user object
};

const ChangePasswordDialog: React.FC<PropsType> = ({ user }) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const { showChangePasswordModal } = useAppSelector((state) => state.app);

  const handleCloseModal = () => {
    dispatch(appActions.closeModal(MODAL.CHANGE_PASSWORD));
  };

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, 'Mật khẩu phải ít nhất 6 ký tự')
      .required('Vui lòng nhập mật khẩu mới'),
    password2: yup
      .string()
      .required('Vui lòng nhập lại mật khẩu mới')
      .oneOf([yup.ref('password'), ''], 'Mật khẩu không trùng khớp'),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      password2: '',
    },
    resolver: yupResolver(schema),
  });

  const submitFormHandler: SubmitHandler<ChangePasswordData> = async (
    data: ChangePasswordData
  ) => {
    setLoading(true); // Set loading to true when submission starts
    try {
      const formData = {
        password: data.password, // Make sure the field name matches the server's expectation
      };
      const { data: response } = await changePassword(formData);
      dispatch(authActions.setUser({ user: response.data.user }));
      dispatch(appActions.closeModal(MODAL.CHANGE_PASSWORD));
      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: 'Đổi mật khẩu thành công',
        })
      );
    } catch (err) {
      dispatch(appActions.closeModal(MODAL.CHANGE_PASSWORD));
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Đổi mật khẩu thất bại',
        })
      );
    } finally {
      setLoading(false); // Set loading to false after submission completes
    }
  };

  return (
    <Dialog open={showChangePasswordModal} onClose={handleCloseModal}>
      <StyledPaper
        sx={{
          maxWidth: 360,
          position: 'relative', // For positioning the close button
        }}
      >
        <Typography
          variant="h4"
          sx={{ padding: (theme: Theme) => theme.spacing(1, 3) }}
          textAlign="center"
        >
          Đổi mật khẩu
        </Typography>
        <Stack direction="column" alignItems="center" mb={1}>
          <Box position="relative">
            <Avatar
              sx={{
                width: 80,
                height: 80,
                border: '3px solid',
                borderColor: (theme: Theme) => theme.palette.primary.main, // Adjust as needed
              }}
              src={user.avatar}
            >
              <AccountCircleIcon />
            </Avatar>
          </Box>
          <Typography
            fontSize="14px"
            fontFamily="SegoeUISemiBold"
            color={(theme: Theme) => theme.palette.text.secondary} // Adjust as needed
          >
            {user.fullName}
          </Typography>
        </Stack>
        <Box
          sx={{
            p: 3,
            backgroundColor: (theme: Theme) => theme.palette.background.paper,
            borderRadius: '30px 30px 0 0',
          }}
        >
          <FormProvider<ChangePasswordData>
            methods={methods}
            handler={submitFormHandler}
          >
            <RHFInput
              fullWidth
              name="password"
              label="Mật khẩu mới"
              type="password"
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </InputAdornment>
              }
              sx={{
                '& .MuiInputBase-root': {
                  color: (theme: Theme) => theme.palette.text.primary, // Adjust as needed
                },
              }}
            />
            <RHFInput
              fullWidth
              name="password2"
              label="Nhập lại mật khẩu mới"
              type="password"
              startAdornment={
                <InputAdornment position="start">
                  <LockOpenIcon
                    fontSize="small"
                    sx={{ color: 'text.primary' }}
                  />
                </InputAdornment>
              }
              sx={{
                '& .MuiInputBase-root': {
                  color: (theme: Theme) => theme.palette.text.primary, // Adjust as needed
                },
              }}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{ display: 'block', mx: 'auto', mt: 3 }}
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Xác nhận'
              )}
            </Button>
          </FormProvider>
        </Box>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleCloseModal}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </StyledPaper>
    </Dialog>
  );
};

export default ChangePasswordDialog;
