import React from 'react';
import { Switch, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { appActions } from '../../redux/slices/appSlice';

export default function ToggleThemeButton() {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector((state) => state.app.isDarkMode);

  const handleToggleTheme = () => {
    dispatch(appActions.toggleDarkMode());
  };

  return (
    <Tooltip title="Toggle Theme">
      <Switch
        checked={isDarkMode}
        onChange={handleToggleTheme}
        color="default"
        inputProps={{ 'aria-label': 'toggle theme' }}
      />
    </Tooltip>
  );
}
