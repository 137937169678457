import React from "react";

import { createSvgIcon } from "@mui/material";

const OmegaIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth={0.1}
    viewBox="0 0 19 19"
    preserveAspectRatio="none"
  >
    <path d="M 9 8 A 1 1 0 0 1 10 9 A 1 1 0 0 1 9 10 A 1 1 0 0 1 8 9 A 1 1 0 0 1 9 8 M 1.22 1.22 C 2.65 -0.21 5.75 0.43 9 2.56 C 12.25 0.43 15.35 -0.21 16.78 1.22 C 18.21 2.65 17.57 5.75 15.44 9 C 17.57 12.25 18.21 15.35 16.78 16.78 C 15.35 18.21 12.25 17.57 9 15.44 C 5.75 17.57 2.65 18.21 1.22 16.78 C -0.21 15.35 0.43 12.25 2.56 9 C 0.43 5.75 -0.21 2.65 1.22 1.22 M 12.54 5.46 C 13.15 6.08 13.71 6.71 14.23 7.34 C 15.61 5.21 16.11 3.38 15.36 2.64 C 14.62 1.89 12.79 2.39 10.66 3.77 C 11.29 4.29 11.92 4.85 12.54 5.46 M 5.46 12.54 C 4.85 11.92 4.29 11.29 3.77 10.66 C 2.39 12.79 1.89 14.62 2.64 15.36 C 3.38 16.11 5.21 15.61 7.34 14.23 C 6.71 13.71 6.08 13.15 5.46 12.54 M 2.64 2.64 C 1.89 3.38 2.39 5.21 3.77 7.34 C 4.29 6.71 4.85 6.08 5.46 5.46 C 6.08 4.85 6.71 4.29 7.34 3.77 C 5.21 2.39 3.38 1.89 2.64 2.64 M 6.88 11.12 C 7.58 11.82 8.3 12.46 9 13.03 C 9.7 12.46 10.42 11.82 11.12 11.12 C 11.82 10.42 12.46 9.7 13.03 9 C 12.46 8.3 11.82 7.58 11.12 6.88 C 10.42 6.18 9.7 5.54 9 4.97 C 8.3 5.54 7.58 6.18 6.88 6.88 C 6.18 7.58 5.54 8.3 4.97 9 C 5.54 9.7 6.18 10.42 6.88 11.12 M 15.36 15.36 C 16.11 14.62 15.61 12.79 14.23 10.66 C 13.71 11.29 13.15 11.92 12.54 12.54 C 11.92 13.15 11.29 13.71 10.66 14.23 C 12.79 15.61 14.62 16.11 15.36 15.36 Z" />
  </svg>,
  "Omega"
);

export default OmegaIcon;
