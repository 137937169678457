import React, { useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  Button,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SchoolIcon from '@mui/icons-material/School';
import CloseIcon from '@mui/icons-material/Close';

// RHF
import FormProvider from '../RHF/FormProvider';
import RHFTextField from '../RHF/RHFTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

// App Component
import { StyledPaper } from './style';

// Data
import { StudentInfo, UpdateProfileData } from '../../model/Student';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MODAL, appActions } from '../../redux/slices/appSlice';
import { updateProfile } from '../../api';
import { authActions } from '../../redux/slices/authSlice';

type PropsType = {
  user: StudentInfo;
};

const UpdateProfileDialog: React.FC<PropsType> = ({ user }) => {
  const dispatch = useAppDispatch();

  const avatarRef = useRef<HTMLInputElement>(null);
  const [selectedAvatarImg, setSelectedAvatarImg] = useState<null | string>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const { showUpdateProfileModal } = useAppSelector((state) => state.app);

  const handleCloseModal = () => {
    dispatch(appActions.closeModal(MODAL.UPDATE_PROFILE));
  };

  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && event.target.files) {
      setSelectedAvatarImg(URL.createObjectURL(event.target.files[0]));
    }
  };

  const schema = yup.object().shape({
    fullName: yup.string().required('Vui lòng nhập tên'),
    email: yup
      .string()
      .email('Vui lòng nhập email hợp lệ')
      .required('Vui lòng nhập email'),
    school: yup.string().required('Vui lòng nhập trường học'),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      fullName: user.fullName,
      email: user.email || '',
      school: user.school || '',
    },
    resolver: yupResolver(schema),
  });

  const submitFormHandler: SubmitHandler<UpdateProfileData> = async (
    data: UpdateProfileData
  ) => {
    setLoading(true); // Set loading to true when submission starts
    try {
      const formData = new FormData();
      if (avatarRef.current?.files)
        formData.append('image', avatarRef.current.files[0]);

      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value.toString());
      }

      const { data: response } = await updateProfile(formData);
      dispatch(authActions.setUser({ user: response.data.user }));
      dispatch(appActions.closeModal(MODAL.UPDATE_PROFILE));
      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: 'Cập nhật thông tin thành công',
        })
      );
      setSelectedAvatarImg(null);
    } catch (err) {
      dispatch(appActions.closeModal(MODAL.UPDATE_PROFILE));
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Cập nhật thông tin thất bại',
        })
      );
    } finally {
      setLoading(false); // Set loading to false after submission completes
    }
  };

  return (
    <Dialog open={showUpdateProfileModal} onClose={handleCloseModal}>
      <StyledPaper
        sx={{
          maxWidth: 360,
        }}
      >
        <Typography
          variant="h4"
          sx={{ padding: (theme) => theme.spacing(1, 3) }}
          textAlign="center"
        >
          Chỉnh sửa thông tin
        </Typography>
        <Stack direction="column" alignItems="center" mb={0} mt={1}>
          <Box position="relative" color="secondary">
            <Avatar
              sx={{
                width: 80,
                height: 80,
              }}
              src={selectedAvatarImg ? selectedAvatarImg : user.avatar}
            />
            <IconButton
              component="label"
              sx={{
                position: 'absolute',
                bottom: -4,
                right: -4,
                padding: '3px',
              }}
            >
              <CameraAltIcon sx={{ width: 25, height: 25 }} />
              <input
                type="file"
                hidden
                accept="image/*"
                ref={avatarRef}
                onChange={handleChangeAvatar}
              />
            </IconButton>
          </Box>
        </Stack>
        <Box sx={{ p: 3, borderRadius: '30px 30px 0 0' }}>
          <FormProvider<UpdateProfileData>
            methods={methods}
            handler={submitFormHandler}
          >
            <RHFTextField
              name="fullName"
              label="Tên đầy đủ"
              startAdornment={
                <InputAdornment position="start">
                  <PersonOutlinedIcon
                    fontSize="small"
                    sx={{ color: 'text.primary' }}
                  />
                </InputAdornment>
              }
              placeholder="Nhập tên đầy đủ"
            />
            <RHFTextField
              name="email"
              label="Email"
              startAdornment={
                <InputAdornment position="start">
                  <MailOutlineIcon
                    fontSize="small"
                    sx={{ color: 'text.primary' }}
                  />
                </InputAdornment>
              }
              placeholder="Nhập email"
            />
            <RHFTextField
              name="school"
              label="Trường học"
              startAdornment={
                <InputAdornment position="start">
                  <SchoolIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </InputAdornment>
              }
              placeholder="Nhập trường học"
            />

            <Button
              variant="contained"
              type="submit"
              sx={{ display: 'block', mx: 'auto', mt: 3 }}
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Xác nhận'
              )}
            </Button>
          </FormProvider>
        </Box>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleCloseModal}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </StyledPaper>
    </Dialog>
  );
};

export default UpdateProfileDialog;
