import React, { useEffect, useState } from 'react';
import { formatDistanceToNow, set } from 'date-fns';
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Collapse,
  Menu,
  MenuItem,
  TextField,
  alpha,
  CircularProgress,
  ListItemIcon,
  Icon,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FolderIcon from '@mui/icons-material/Folder';
import CourseActions from './CourseActions';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import {
  addCourseItem,
  deleteCourseItem,
  updateCourseItem,
} from '../../redux/slices/courseItemSlice';
import {
  setCourseItems,
  setLoading,
  setError,
} from '../../redux/slices/courseItemSlice';
import {
  addContactLinkToCourse,
  addLearningObjectiveToCourse,
  addLinkToCourse,
  createNewSubFolder,
  deleteExamById,
  deleteLearningObjectiveFromCourse,
  deleteLinkFromCourse,
  deletePDFById,
  deleteSubFolderById,
  deleteVideoById,
  getCourseById,
  updateSubFolderById,
} from '../../api';
import useAuth from '../../hooks/useAuth';
import CourseDetails from './CourseDetails';
import CourseImage from './CourseImage';
import CourseInfoSection from './CourseInfoSection';
import DialogForm from './DialogForm';
import ExamTabs from '../Exams';
import { AdminActions, CircleButton, ListItemIconCustom } from '../Home/Course';
import DeleteIcon from '@mui/icons-material/Delete';
import { appActions } from '../../redux/slices/appSlice';
import ConfirmDialog from '../Home/ComfirmDialog';
import EditIcon from '@mui/icons-material/Edit';
import VideoPdfPage from '../VideoPdf';
import SubFolderDialogItem from './SubFolderDialogItem';
import { vi } from 'date-fns/locale';
import LoadingDisplay from '../Home/Loading';
import ErrorDisplay from '../Home/Error';

const CoursesPage = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogUrl, setDialogUrl] = useState('');
  const [openSubfolders, setOpenSubfolders] = useState<string[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogContent, setDialogContent] = useState<React.ReactNode | null>(
    null
  );
  const [activeItem, setActiveItem] = useState({
    itemId: '',
    type: '',
  });

  const { courseId } = useParams();

  const courseContent = useSelector(
    (state: RootState) => state.courseItems.course
  );
  const loading = useSelector((state: RootState) => state.courseItems.loading);
  const error = useSelector((state: RootState) => state.courseItems.error);

  useEffect(() => {
    const loadCourse = async () => {
      dispatch(setLoading(true)); // Bắt đầu tải
      try {
        if (courseId) {
          const data = await getCourseById(courseId);
          dispatch(setCourseItems(data)); // Gửi action setCourseItems
        } else {
          throw new Error('No course ID provided');
        }
      } catch (err) {
        console.error('Lỗi khi tải khóa học:', err);
        dispatch(setError('Không thể tải khóa học'));
      } finally {
        dispatch(setLoading(false)); // Dừng tải
      }
    };

    loadCourse();
  }, [courseId, dispatch]);

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAddLink = async (link: string) => {
    try {
      // Cập nhật khóa học với liên kết mới
      if (!courseId) return;

      await addLinkToCourse(courseId, link);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi thêm liên kết:', err);
      dispatch(setError('Không thể thêm liên kết'));
    }
  };

  const handleDeleteLink = async (link: string) => {
    try {
      // Xóa liên kết khỏi khóa học
      if (!courseId) return;
      console.log('Đang xóa liên kết:', link);

      await deleteLinkFromCourse(courseId, link);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi xóa liên kết:', err);
      dispatch(setError('Không thể xóa liên kết'));
    }
  };

  const handleAddContactLink = async (link: string) => {
    try {
      // Cập nhật khóa học với liên kết liên hệ mới
      if (!courseId) return;
      console.log('Đang thêm liên kết liên hệ:', link);

      await addContactLinkToCourse(courseId, link);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi thêm liên kết:', err);
      dispatch(setError('Không thể thêm liên kết'));
    }
  };

  const handleAddLearningObjective = async (objective: string) => {
    try {
      // Cập nhật khóa học với mục tiêu học tập mới
      if (!courseId) return;
      console.log('Đang thêm mục tiêu học tập:', objective);
      await addLearningObjectiveToCourse(courseId, objective);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi thêm mục tiêu học tập:', err);
      dispatch(setError('Không thể thêm mục tiêu học tập'));
    }
  };

  const handleDeleteLearningObjective = async (objective: string) => {
    try {
      // Xóa mục tiêu học tập khỏi khóa học
      if (!courseId) return;

      await deleteLearningObjectiveFromCourse(courseId, objective);
      window.location.reload();
    } catch (err) {
      console.error('Lỗi khi xóa mục tiêu học tập:', err);
      dispatch(setError('Không thể xóa mục tiêu học tập'));
    }
  };

  const handleCreateSubFolder = async (
    name: string,
    parentFolderId: string
  ) => {
    try {
      if (!courseId) return;
      const res = await createNewSubFolder(courseId, parentFolderId, name);
      const newSubFolderWithType = {
        ...res.data,
        type: 'subfolder',
        itemId: res.data.subFolderId,
      };
      dispatch(addCourseItem(newSubFolderWithType));
      console.log('Đang tạo thư mục con:', name, parentFolderId);
    } catch (err) {
      console.error('Lỗi khi tạo thư mục con:', err);
      dispatch(setError('Không thể tạo thư mục con'));
    }
    setOpenDialog(false);
  };

  const handleCreateNewItem = async (type: string) => {
    setOpenDialog(true);
    let parentFolderId = null;
    if (activeItem.type === 'subfolder') {
      parentFolderId = activeItem.itemId;
    }
    try {
      if (type === 'exam') {
        setDialogContent(
          <ExamTabs
            mode="create"
            courseId={courseId}
            parentFolderId={parentFolderId || courseId}
            closeDialog={handleCloseDialog}
          />
        );
      } else if (type === 'pdf') {
        setDialogContent(
          <VideoPdfPage
            itemId={activeItem.itemId}
            mode="create"
            courseId={courseId}
            parentFolderId={parentFolderId || courseId}
            closeDialog={handleCloseDialog}
            type="pdf"
          />
        );
      } else if (type === 'video') {
        setDialogContent(
          <VideoPdfPage
            itemId={activeItem.itemId}
            mode="create"
            courseId={courseId}
            parentFolderId={parentFolderId || courseId}
            closeDialog={handleCloseDialog}
            type="video"
          />
        );
      } else if (type === 'subfolder') {
        setDialogContent(
          <SubFolderDialogItem
            onSaveClick={(name: string) => {
              handleCreateSubFolder(name, courseId || '');
            }}
            onClose={handleCloseDialog}
          />
        );
      }
    } catch (err) {
      console.error('Lỗi khi tạo mục mới:', err);
      dispatch(setError('Không thể tạo mục mới'));
    } finally {
      setLoading(false);
      handleCloseMenu();
    }
  };

  const handleOpenDialog = (id: string, type: string) => {
    setOpenDialog(true);
    if (type === 'exam') {
      setDialogContent(
        <ExamTabs examId={id} closeDialog={handleCloseDialog} />
      );
    } else if (type === 'video' || type === 'pdf') {
      setDialogContent(
        <VideoPdfPage
          itemId={id}
          mode="view"
          closeDialog={handleCloseDialog}
          type={type}
        />
      );
    } else if (type === 'subfolder') {
      setDialogContent(<></>);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const toggleSubfolder = (subfolderId: string) => {
    setOpenSubfolders((prev) =>
      prev.includes(subfolderId)
        ? prev.filter((id) => id !== subfolderId)
        : [...prev, subfolderId]
    );
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const { itemId, type } = activeItem;
      if (type === 'exam') {
        await deleteExamById(itemId);
      } else if (type === 'video') {
        await deleteVideoById(itemId);
      } else if (type === 'pdf') {
        await deletePDFById(itemId);
      } else if (type === 'subfolder') {
        // Xóa thư mục con
        await deleteSubFolderById(itemId);
      }
      dispatch(
        deleteCourseItem({
          itemId: itemId,
          type: type,
        })
      );
    } catch (error) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Không thể xóa khóa học',
        })
      );
    } finally {
      setLoading(false);
      setOpenConfirmation(false);
    }
  };

  const handleEdit = (id: string, type: string) => {
    setOpenDialog(true);
    try {
      if (type === 'exam') {
        setDialogContent(
          <ExamTabs mode="edit" closeDialog={handleCloseDialog} examId={id} />
        );
      } else if (type === 'video' || type === 'pdf') {
        setDialogContent(
          <VideoPdfPage
            itemId={id}
            mode="edit"
            closeDialog={handleCloseDialog}
            type={type}
          />
        );
      } else if (type === 'subfolder') {
        setDialogContent(
          <SubFolderDialogItem
            onSaveClick={async (title: string) => {
              const newUpdatedSubFolder = await updateSubFolderById(id, {
                title: title,
              });

              // Tìm thư mục con trong danh sách và cập nhật tiêu đề
              let updatedSubFolder;
              for (let i = 0; i < courseContent.items.length; i++) {
                if (courseContent.items[i].itemId === id) {
                  updatedSubFolder = {
                    ...courseContent.items[i],
                    title: title,
                  };

                  break;
                }
              }

              if (updatedSubFolder)
                dispatch(updateCourseItem(updatedSubFolder));
              handleCloseDialog();
            }}
            onClose={handleCloseDialog}
            subFolderId={id}
          />
        );
      }
    } catch (err) {
      console.error('Lỗi khi tạo mục mới:', err);
      dispatch(setError('Không thể tạo mục mới'));
    } finally {
      setLoading(false);
      handleCloseMenu();
    }
  };

  const renderItems = (items: any[] = []) => (
    <List sx={{ pt: 0 }}>
      {items.map((item) => (
        <div key={item.type + item.itemId}>
          <ListItem sx={{ padding: 0 }}>
            <>
              <Button
                fullWidth
                variant="outlined"
                onClick={() =>
                  item.type === 'subfolder'
                    ? toggleSubfolder(item.itemId)
                    : handleOpenDialog(item.itemId, item.type)
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'none',
                  borderRadius: '0px',
                  borderColor: 'primary.main',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  '&:hover': {
                    borderColor: 'primary.dark',
                    backgroundColor: 'primary.light',
                  },
                  backgroundColor:
                    item.type === 'subfolder'
                      ? 'primary.light'
                      : 'background.default',
                }}
              >
                <Box display="flex" alignItems="center" flex="1">
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      marginRight: 1.5,
                      marginLeft: 0.5,
                      minWidth: 0, // Xóa minWidth mặc định của nút
                      color:
                        item.type === 'subfolder'
                          ? 'text.primary'
                          : 'primary.main',
                      '&:hover': {
                        backgroundColor: 'transparent', // Xóa màu nền khi di chuột
                      },
                      '&:active': {
                        backgroundColor: 'transparent', // Xóa màu nền khi nhấn
                      },
                    }}
                  >
                    {item.type === 'exam' && <DescriptionIcon />}
                    {item.type === 'video' && <YouTubeIcon />}
                    {item.type === 'pdf' && <PictureAsPdfIcon />}
                    {item.type === 'subfolder' &&
                      (openSubfolders.includes(item.itemId) ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      ))}
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    justifyContent="space-between"
                  >
                    <ListItemText
                      primary={item.title}
                      primaryTypographyProps={{
                        noWrap: true,
                        color: 'text.primary',
                        fontWeight: '500', // Làm tiêu đề đậm
                        variant: 'subtitle1', // Điều chỉnh kích thước tiêu đề
                        marginBottom: '2px', // Giảm khoảng cách giữa tiêu đề và hàng dưới
                      }}
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      color="text.secondary"
                    >
                      {item.type === 'exam' && (
                        <Typography variant="caption">
                          {' '}
                          {/* Kích thước văn bản nhỏ hơn */}
                          {item.examTimes} người tham gia
                        </Typography>
                      )}
                      {item.type !== 'exam' && item.type !== 'subfolder' && (
                        <Typography variant="caption">
                          {' '}
                          {/* Kích thước văn bản nhỏ hơn */}
                          {item.views} lượt xem
                        </Typography>
                      )}
                      {item.createdAt && item.tpye !== 'subfolder' && (
                        <>
                          <Typography variant="caption" sx={{ mx: 1 }}>
                            {' '}
                            {/* Kích thước văn bản nhỏ hơn */}•
                          </Typography>
                          <Typography variant="caption">
                            {' '}
                            {/* Kích thước văn bản nhỏ hơn */}
                            {formatDistanceToNow(new Date(item.createdAt), {
                              addSuffix: true,
                              locale: vi,
                            })}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Button>
              {user && (user.role === 'admin' || user.role === 'teacher') && (
                <AdminActions>
                  <CircleButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(item.itemId, item.type);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </CircleButton>
                  <CircleButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveItem({ itemId: item.itemId, type: item.type });
                      setOpenConfirmation(true); // Mở hộp thoại xác nhận
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </CircleButton>
                  {item.type === 'subfolder' && (
                    <CircleButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveItem({
                          itemId: item.itemId,
                          type: item.type,
                        });
                        handleAddClick(e);
                      }}
                    >
                      <AddIcon fontSize="small" />
                    </CircleButton>
                  )}
                </AdminActions>
              )}
            </>
          </ListItem>
          {item.type === 'subfolder' && (
            <Collapse
              in={openSubfolders.includes(item.itemId)}
              timeout="auto"
              unmountOnExit
            >
              {renderItems(item.items)}
            </Collapse>
          )}
        </div>
      ))}
    </List>
  );
  if (loading) {
    return <LoadingDisplay loadingMessage="Đang tải chi tiết khóa học..." />;
  }

  if (error) {
    return <ErrorDisplay errorMessage="Đã xảy ra lỗi. Vui lòng thử lại sau." />;
  }

  return (
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="space-between" padding={2}>
        <Box flex="1" maxWidth="70%" sx={{ padding: 2, position: 'relative' }}>
          {/* Render nút Thêm theo điều kiện */}
          {user && (user.role === 'teacher' || user.role === 'admin') && (
            <>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 20,
                  right: 20,
                  zIndex: 1,
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
                onClick={handleAddClick}
              >
                <AddIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={() => handleCreateNewItem('exam')}>
                  <ListItemIconCustom>
                    <DescriptionIcon />
                  </ListItemIconCustom>
                  <Typography variant="caption">Bài kiểm tra</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleCreateNewItem('pdf')}>
                  <ListItemIconCustom>
                    <PictureAsPdfIcon />
                  </ListItemIconCustom>
                  <Typography variant="caption">PDF</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleCreateNewItem('video')}>
                  <ListItemIconCustom>
                    <YouTubeIcon />
                  </ListItemIconCustom>
                  <Typography variant="caption">Video</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleCreateNewItem('subfolder')}>
                  <ListItemIconCustom>
                    <FolderIcon />
                  </ListItemIconCustom>
                  <Typography variant="caption">Thư mục mới</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
          <CourseDetails courseContent={courseContent} />
          <div style={{ marginTop: '8px' }}>
            {renderItems(courseContent.items)}
          </div>
        </Box>

        <Box flex="1" maxWidth="25%">
          <CourseImage image={courseContent.image} />
          <CourseInfoSection
            courseContent={courseContent}
            user={user}
            onAddNewLink={(link: string) => handleAddLink(link)}
            onAddNewContactLink={(link: string) => handleAddContactLink(link)}
            onDeleteLink={(link: string) => handleDeleteLink(link)}
            onAddNewLearningObjective={(objective: string) =>
              handleAddLearningObjective(objective)
            }
            onDeleteLearningObjective={(objective: string) =>
              handleDeleteLearningObjective(objective)
            }
          />

          <CourseActions
            user={user}
            courseId={courseId || ''}
            contactLink={courseContent.contactLink}
            price={courseContent.price || 0}
          />
        </Box>
      </Box>
      <DialogForm
        open={openDialog}
        onClose={handleCloseDialog}
        title={dialogTitle}
        url={dialogUrl}
        content={dialogContent}
      />
      <ConfirmDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => handleDelete()}
        title="Xóa mục"
        content="Bạn có chắc chắn muốn xóa mục này không?"
        loading={loading}
      />
    </Container>
  );
};

export default CoursesPage;
