import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { appActions } from '../redux/slices/appSlice';

const OKCancelNotification = () => {
  const state = useAppSelector((state) => state.app.okCancelNotification);
  const dispatch = useAppDispatch();

  const handleOk = () => {
    dispatch(appActions.hideOKCancelNotification(true));
  };

  const handleCancel = () => {
    // Handle Cancel action here
    dispatch(appActions.hideOKCancelNotification(false));
  };

  return (
    <Dialog open={state.open} onClose={handleCancel}>
      <DialogTitle>Notification</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{state.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OKCancelNotification;
