// // Question schema
// const QuestionSchema = new Schema({
//   section: {
//     //Section 1, 2, 3
//     type: String,
//     required: true,
//   },
//   type: {
//     type: String,
//     required: true,
//   }, // Multiple choice ABCD named as 'mcq', multiple small checkboxes true/false named as 'mctf',
//   //short answer named as 'sa'
//   correctAnswer: {
//     type: String, // for mcq: A, B, C, D, for mctf: 1001, 1 is true, 0 is false, based on the order of the options
//     //, for sa: the correct answer exapmle: '-2.5'
//     required: true,
//   },
// });

// Updated Question interface based on the Mongoose schema
export interface Question {
  questionId: string; // Unique identifier for the question
  section: string; // Section 1, 2, 3
  index: number; // Question number
  type: 'mcq' | 'mctf' | 'sa'; // Question types: Multiple choice ('mcq'), Multiple checkboxes true/false ('mctf'), Short answer ('sa')
  correctAnswer: string; // For mcq: A, B, C, D, for mctf: 1001, for sa: example '-2.5'
  questionUrl: string; // URL to the question image or resource
  answerUrl: string; // URL to the answer image or resource
  questionFile: File | null; // File for the question image or resource
  answerFile: File | null; // File for the answer image or resource
}

// Default objects for different types of questions
export const defaultQuestionMCQ: Question = {
  questionId: 'mcq_001', // Example question ID
  section: 'Phần I', // Dynamically provided section
  index: 1, // Example question index
  type: 'mcq', // Default question type: Multiple choice
  correctAnswer: 'A', // Default correct answer for MCQ
  questionUrl: '', // Example question URL
  answerUrl: '', // Example answer URL
  questionFile: null, // Default question file
  answerFile: null, // Default answer file
};

export const defaultQuestionMCTF: Question = {
  questionId: 'mctf_001', // Example question ID
  section: 'Phần II', // Dynamically provided section
  index: 1, // Example question index
  type: 'mctf', // Default question type: Multiple checkboxes true/false
  correctAnswer: '1111', // Default correct answer for MCTF (true for all options)
  questionUrl: '', // Example question URL
  answerUrl: '', // Example answer URL
  questionFile: null, // Default question file
  answerFile: null, // Default answer file
};

export const defaultQuestionSA: Question = {
  questionId: 'sa_001', // Example question ID
  section: 'Phần III', // Dynamically provided section
  index: 1, // Example question index
  type: 'sa', // Default question type: Short answer
  correctAnswer: '0', // Default correct answer for short answer
  questionUrl: '', // Example question URL
  answerUrl: '', // Example answer URL
  questionFile: null, // Default question file
  answerFile: null, // Default answer file
};

// Default form for THPT Quốc Gia, including 3 sections
// Section 1: 18 questions, multiple choice ABCD
// Section 2: 4 questions, multiple small checkboxes true/false
// Section 3: 6 questions, short answer
export const defaultQuestions: Array<Question> = [
  // Map for Section 1: 18 MCQ questions
  ...Array(18)
    .fill(defaultQuestionMCQ)
    .map((question, index) => ({
      ...question,
      index: index + 1, // Index starts from 1 for Section 1
      section: 'Phần I', // Set section name
    })),

  // Map for Section 2: 4 MCTF questions
  ...Array(4)
    .fill(defaultQuestionMCTF)
    .map((question, index) => ({
      ...question,
      index: index + 1, // Index starts from 1 for Section 2
      section: 'Phần II', // Set section name
    })),

  // Map for Section 3: 6 Short Answer questions
  ...Array(6)
    .fill(defaultQuestionSA)
    .map((question, index) => ({
      ...question,
      index: index + 1, // Index starts from 1 for Section 3
      section: 'Phần III', // Set section name
    })),
];

export interface Exam {
  examId: string; // Unique identifier for the exam
  courseId: string; // ID of the course to which the exam belongs
  parentFolderId: string; // ID of the parent folder
  questionURL: string; // URL to the question PDF file
  answerURL: string; // URL to the answer PDF file
  title: string; // Title of the exam
  duration: number; // Duration of the exam in minutes
  subject: string; // Subject of the exam
  examTimes: number; // Number of times the exam has been taken
  createdAt?: Date; // Date when the exam was created
  executedAt?: Date; // Date when the exam was executed (optional)
  questions: Array<any>; // Array to hold the questions; replace `any` with the specific type as needed
}

export const defaultExam: Exam = {
  examId: '', // This will be generated by the server or can be set manually if needed
  courseId: '', // This will be provided dynamically
  parentFolderId: '', // This will be provided dynamically
  questionURL: '', // Replace with a default URL or leave empty
  answerURL: '', // Replace with a default URL or leave empty
  title: 'New Exam', // Default title
  duration: 60, // Default duration in minutes
  subject: 'General', // Default subject
  examTimes: 0, // Default number of times the exam has been taken
  createdAt: new Date(), // Default creation date
  executedAt: new Date(), // Default execution date
  questions: [], // Default questions array
};

export interface ExamInput {
  questionURL: string;
  answerURL: string;
  questionPdf: File | null;
  answerPdf: File | null;
  title: string;
  duration: string;
  subject: string;
  executedAt: Date | null;
  questions: Array<Question>;
}

export interface ExamPageProps {
  exam: Exam;
  mode?: 'view' | 'edit' | 'create';
  examInput: ExamInput;
  setExamInput: React.Dispatch<React.SetStateAction<ExamInput>>;
}

export const defaultExamInput: ExamPageProps['examInput'] = {
  questionURL: '',
  answerURL: '',
  questionPdf: null,
  answerPdf: null,
  title: 'New Exam',
  duration: '60',
  subject: 'General',
  executedAt: new Date(),
  questions: defaultQuestions,
};
