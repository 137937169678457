import { CssBaseline } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { useMemo, useState } from 'react';

import palette from './palette';
import lightPalette from './lightpalette';
import typography from './typography';
import components from './components';
import { useAppSelector } from '../hooks/redux';

const ThemeProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const isDarkMode = useAppSelector((state) => state.app.isDarkMode);
  const currentTheme = isDarkMode ? palette : lightPalette;

  const themeOptions = useMemo(
    () => ({
      palette: currentTheme,
      typography,
      components,
      shape: { borderRadius: 8 },
    }),
    [currentTheme]
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
