import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DialogFormProps {
  open: boolean;
  onClose: () => void;
  title: string;
  url: string;
  content?: React.ReactNode; // Add this line
}

const DialogForm: React.FC<DialogFormProps> = ({
  open,
  onClose,
  title,
  url,
  content, // Add this line
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="xl"
    PaperProps={{ sx: { borderRadius: '0' } }}
  >
    <DialogContent sx={{ px: 0, pt: 0 }}>
      {content ? (
        content
      ) : (
        <iframe
          src={url}
          style={{ width: '100%', height: '80vh', border: 'none' }}
          title={title}
        />
      )}
    </DialogContent>
    {/* <DialogActions>
      <Button variant="outlined" onClick={onClose}>
        Close
      </Button>
    </DialogActions> */}
  </Dialog>
);

export default DialogForm;
