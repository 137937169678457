import React, { useState, useEffect } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Tabs,
  Tab,
} from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ExamPage from './ExamPage';
import { defaultExam, defaultExamInput, Exam, ExamInput } from './type';
import {
  addQuestion,
  createNewExam,
  fetchExamById,
  updateExamById,
  updateQuestionById,
} from '../../api';
import dayjs from 'dayjs';
import AnswerResultPage from './AnswerResultPage';
import { useDispatch } from 'react-redux';
import {
  addCourseItem,
  updateCourseItem,
} from '../../redux/slices/courseItemSlice';
import { appActions } from '../../redux/slices/appSlice';

interface ExamTabsProps {
  examId?: string;
  mode?: 'view' | 'edit' | 'create';
  courseId?: string;
  parentFolderId?: string;
  closeDialog?: () => void;
}

const steps = ['Exam', 'Answer & Result'];
const fullSteps = ['Exam', 'Answer & Result', 'Leader Board'];

const ExamTabs: React.FC<ExamTabsProps> = ({
  examId,
  mode = 'view',
  courseId = '',
  parentFolderId = '',
  closeDialog = () => {},
}) => {
  const { examId: urlExamId } = useParams<{ examId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [exam, setExam] = useState<Exam | null>(defaultExam);
  const [examInput, setExamInput] = useState(defaultExamInput);

  //Remove the text after the last slash
  const path = location.pathname.substring(
    0,
    location.pathname.lastIndexOf('/')
  );

  const isNotOnDialog =
    path.includes('exam') ||
    path.includes('answer') ||
    path.includes('leaderboard');

  const height = isNotOnDialog ? '90vh' : '80vh';

  // Fetch exam data based on the examId
  useEffect(() => {
    const loadExam = async () => {
      try {
        const id = examId || urlExamId;
        if (!id) return;

        const res = await fetchExamById(id);
        const data = res.data;
        setExam(data);

        setExamInput({
          questionURL: data.questionURL || '',
          answerURL: data.answerURL || '',
          title: data.title || '',
          duration: data.duration || '',
          subject: data.subject || '',
          executedAt: data.executedAt ? new Date(data.executedAt) : null,
        } as ExamInput);
      } catch (err) {
        console.error('Error fetching exam:', err);
      }
    };

    loadExam();
  }, [examId, urlExamId]);

  // Determine active tab based on URL
  useEffect(() => {
    if (location.pathname.includes('leaderboard')) {
      setActiveTab(2);
    } else if (location.pathname.includes('answer')) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  }, [location.pathname]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    const id = examId || urlExamId;
    if (isNotOnDialog) {
      switch (newValue) {
        case 0:
          navigate(`/exam/${id}`);
          break;
        case 1:
          navigate(`/answer/${id}`);
          break;
        case 2:
          navigate(`/leaderboard/${id}`);
          break;
        default:
          break;
      }
    }
  };

  // Handle Save Button Click
  const handleSave = async () => {
    try {
      // Prepare updated exam data
      // const updatedExamItem: Exam = {
      //   ...exam!,
      //   questionURL: examInput.questionURL,
      //   answerURL: examInput.answerURL,
      //   title: examInput.title,
      //   duration: Number(examInput.duration),
      //   subject: examInput.subject,
      //   executedAt: examInput.executedAt
      //     ? dayjs(examInput.executedAt).toDate()
      //     : undefined,
      // };

      const updatedExam: FormData = new FormData();
      updatedExam.append('questionURL', examInput.questionURL);
      updatedExam.append('answerURL', examInput.answerURL);
      updatedExam.append('title', examInput.title);
      updatedExam.append('duration', examInput.duration);
      updatedExam.append('subject', examInput.subject);
      examInput.executedAt &&
        updatedExam.append('executedAt', examInput.executedAt.toISOString());
      examInput.questionPdf &&
        updatedExam.append('questionPdf', examInput.questionPdf);
      examInput.answerPdf &&
        updatedExam.append('answerPdf', examInput.answerPdf);

      // Save to database
      if (mode === 'edit') {
        // Update existing exam
        if (!examId) return;
        const updatedItem = await updateExamById(examId, updatedExam);
        const updatedItemWithType = {
          ...updatedItem.data,
          type: 'exam',
          itemId: updatedItem.data.examId,
        };
        if (examInput.answerPdf) {
          const editQuestions = async () => {
            for (const question of examInput.questions) {
              const questionData = new FormData();
              questionData.append('section', question.section);
              questionData.append('index', question.index.toString());
              questionData.append('type', question.type);
              questionData.append('correctAnswer', question.correctAnswer);
              questionData.append('questionUrl', question.questionUrl);
              questionData.append('answerUrl', question.answerUrl);
              question.questionFile &&
                questionData.append('questionFile', question.questionFile);
              question.answerFile &&
                questionData.append('answerFile', question.answerFile);
              questionData.append('examId', examId);
              try {
                const newQuestion = await updateQuestionById(
                  question.questionId,
                  questionData
                );
                // Handle success, e.g., log or update state
              } catch (error) {
                // Handle error for each question
                console.error(
                  `Failed to add question ${question.index}:`,
                  error
                );
              }
            }
          };
          await editQuestions();
        }

        dispatch(updateCourseItem(updatedItemWithType));
      } else if (mode === 'create') {
        // Create new exam
        updatedExam.append('courseId', courseId);
        updatedExam.append('parentFolderId', parentFolderId);

        const newItem = await createNewExam(updatedExam);
        const newItemWithType = {
          ...newItem.data,
          type: 'exam',
          itemId: newItem.data.examId,
        };

        // Post all questions to server
        const postQuestions = async () => {
          for (const question of examInput.questions) {
            const questionData = new FormData();
            questionData.append('section', question.section);
            questionData.append('index', question.index.toString());
            questionData.append('type', question.type);
            questionData.append('correctAnswer', question.correctAnswer);
            questionData.append('questionUrl', question.questionUrl);
            questionData.append('answerUrl', question.answerUrl);
            question.questionFile &&
              questionData.append('questionFile', question.questionFile);
            question.answerFile &&
              questionData.append('answerFile', question.answerFile);
            questionData.append('examId', newItem.data.examId);

            try {
              const newQuestion = await addQuestion(questionData);
              // Handle success, e.g., log or update state
            } catch (error) {
              // Handle error for each question
              console.error(`Failed to add question ${question.index}:`, error);
            }
          }
        };

        // Call the function to post all questions
        await postQuestions();

        dispatch(addCourseItem(newItemWithType));
      }

      //closeDialog();
    } catch (err) {
      console.error('Error saving exam:', err);
    }
  };

  const renderContent = () => {
    return (
      <>
        <div
          style={{
            display: activeTab === 0 ? 'block' : 'none',
            height: '100%',
          }}
        >
          <ExamPage
            exam={exam || defaultExam}
            mode={mode}
            examInput={examInput}
            setExamInput={setExamInput}
          />
        </div>
        <div
          style={{
            display: activeTab === 1 ? 'block' : 'none',
            height: '100%',
          }}
        >
          <AnswerResultPage
            exam={exam || defaultExam}
            mode={mode}
            examInput={examInput}
            setExamInput={setExamInput}
          />
        </div>
        <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
          <Typography variant="h6">Leader Board Content Goes Here</Typography>
        </div>
      </>
    );
  };

  const stepLabels = mode === 'view' ? fullSteps : steps;

  return (
    <Box sx={{ width: '100%', mt: 3, px: 3 }}>
      {/* Stepper */}
      {mode === 'view' ? (
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {stepLabels.map((label) => (
            <Tab key={label} label={label} />
          ))}
        </Tabs>
      ) : (
        <Stepper activeStep={activeTab} alternativeLabel>
          {stepLabels.map((label, index) => (
            <Step key={index} onClick={() => setActiveTab(index)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {/* Content */}
      <Box sx={{ my: 1, height: height, maxHeight: height }}>
        {renderContent()}
      </Box>
      {/* Save Button */}
      {!isNotOnDialog && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          {(mode === 'edit' || mode === 'create') && (
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={closeDialog}
            sx={{ ml: 1 }}
          >
            Close
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ExamTabs;
