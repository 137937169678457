const paths = {
  HOME: '/',
  AUTH: '/auth',
  EXAM: '/exam/:examId',
  ANSWER: '/answer/:examId',
  LEADERBOARD: '/leaderboard/:examId',
  BIO: '/bio',
  EXAM_SHEET: '/sheet/:normalizedName',
  ANSWER_SHEET: '/answersheet/:normalizedName',
  COURSE: '/course/:courseId',
};

export default paths;
