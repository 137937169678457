import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Typography,
  Grid,
} from '@mui/material';
import Course from './Course'; // Import the Course component
import useAuth from '../../hooks/useAuth';
import CreateCourseForm from './CreateCourseForm';
import { fetchAllCourses } from '../../api'; // Import the fetchAllCourses function
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/index';
import { setCourses } from '../../redux/slices/courseSlice';
import { useNavigate } from 'react-router-dom';
import ErrorDisplay from './Error';
import LoadingDisplay from './Loading';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const courses = useSelector((state: RootState) => state.course.courses);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const data = await fetchAllCourses();
        dispatch(setCourses(data)); // Dispatch setCourses action
      } catch (err) {
        console.error('Error fetching courses:', err);
        setError('Failed to load courses');
      } finally {
        setLoading(false);
      }
    };

    loadCourses();
  }, [dispatch]);

  const handleCreateCourse = () => {
    setShowCreateForm(true);
  };

  const handleCloseForm = () => {
    setShowCreateForm(false);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ position: 'relative', mb: 4 }}>
        {user && (user.role === 'admin' || user.role === 'teacher') && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              position: 'relative',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              my: 2,
            }}
            onClick={handleCreateCourse}
          >
            Tạo khóa học mới
          </Button>
        )}

        {showCreateForm && (
          <Box
            sx={{
              position: 'absolute',
              top: '60px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              maxWidth: '600px',
              padding: 2,
              backgroundColor: 'background.paper',
              boxShadow: 3,
              zIndex: 10,
            }}
          >
            <CreateCourseForm onClose={handleCloseForm} />
          </Box>
        )}

        {loading && (
          <LoadingDisplay loadingMessage="Đang tải chi tiết khóa học..." />
        )}

        {error && (
          <ErrorDisplay errorMessage="Đã xảy ra lỗi. Vui lòng thử lại sau." />
        )}

        {!loading && !error && (
          <Grid container spacing={2}>
            {courses.map((course) => (
              <Grid
                item
                xs={12} // Full width on extra small screens
                sm={6} // Half width on small screens and above
                md={4} // One-third width on medium screens and above (adjust as needed)
                lg={3} // One-fourth width on large screens and above (adjust as needed)
                key={course.courseId}
              >
                <Course
                  courseId={course.courseId}
                  image={course.image}
                  title={course.title}
                  description={course.description}
                  favorites={course.favorites}
                  members={course.members}
                  exams={course.exams}
                  videos={course.videos}
                  pdfs={course.pdfs}
                  createdAt={course.createdAt}
                  lastUpdatedAt={course.lastUpdatedAt}
                  createdBy={course.createdBy}
                  price={course.price}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default HomePage;
