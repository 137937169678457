import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NavLinkStyled from './style';
import { Link as RouterLink } from 'react-router-dom';
import content from '../../constants/content';
import Logo from '../Logo';
import useResponsive from '../../hooks/useResponsive';
import { useAppDispatch } from '../../hooks/redux';
import { MODAL, appActions } from '../../redux/slices/appSlice';
import useAuth from '../../hooks/useAuth';
import MESSAGE from '../../constants/message';
import { logout } from '../../api';
import { authActions } from '../../redux/slices/authSlice';
import { Stack } from '@mui/material';
import paths from '../../constants/paths';
import SearchBar from './SearchBar';
import ToggleThemeButton from './ToggleThemeButton';
import EditIcon from '@mui/icons-material/Edit';
import PasswordIcon from '@mui/icons-material/VpnKey';
import LogoutIcon from '@mui/icons-material/Logout';
import { ListItemIconCustom } from '../../pages/Home/Course';

function ResponsiveAppBar() {
  const dispatch = useAppDispatch();
  const isDesktop = useResponsive('up', 'md');
  const [openNav, setOpenNav] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [searchOpen, setSearchOpen] = React.useState(false);
  const userActions = [
    {
      label: 'Cập nhật thông tin',
      icon: <EditIcon fontSize="small" />,
      handler: () => {
        dispatch(appActions.showModal(MODAL.UPDATE_PROFILE));
        setAnchorElUser(null);
      },
    },
    {
      label: 'Đổi mật khẩu',
      icon: <PasswordIcon fontSize="small" />,
      handler: () => {
        dispatch(appActions.showModal(MODAL.CHANGE_PASSWORD));
        setAnchorElUser(null);
      },
    },
  ];

  const { user } = useAuth();

  // Mobile
  const handleOpenNavMenu = () => {
    setOpenNav(true);
  };

  const handleCloseNavMenu = () => {
    setOpenNav(false);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // End Mobile

  // Auth Modal
  const handleOpenLoginModal = () => {
    dispatch(appActions.toggleShowLoginModal());
    handleCloseUserMenu();
  };
  const handleOpenRegisterModal = () => {
    dispatch(appActions.toggleShowRegisterModal());
    handleCloseUserMenu();
  };
  // End Auth modal

  // USER ACTION
  const [anchorElUserAction, setAnchorElUserAction] =
    React.useState<null | HTMLElement>(null);

  const handleOpenUserActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUserAction(event.currentTarget);
  };
  const handleCloseUserActionMenu = () => {
    setAnchorElUserAction(null);
  };
  // END USER ACTION

  // Logout
  const handleLogout = async () => {
    try {
      await logout();
      dispatch(authActions.logout());
      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: MESSAGE.LOGOUT_SUCCESS,
        })
      );
      handleCloseUserActionMenu();
    } catch (err) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: MESSAGE.UNKNOWN_ERROR,
        })
      );
    }
  };

  const notAuthAction = (
    <Box
      sx={{
        flexGrow: 0,
        display: { xs: 'none', md: 'flex' },
        minWidth: '200px',
      }}
    >
      <NavLinkStyled mr={2} onClick={handleOpenLoginModal}>
        {
          <content.NAV_AUTH.login.icon
            sx={{ mr: 0.6, width: '18px', height: '18px' }}
          />
        }

        {content.NAV_AUTH.login.text}
      </NavLinkStyled>
      <NavLinkStyled onClick={handleOpenRegisterModal}>
        {
          <content.NAV_AUTH.register.icon
            sx={{ mr: 0.6, width: '18px', height: '18px' }}
          />
        }

        {content.NAV_AUTH.register.text}
      </NavLinkStyled>
    </Box>
  );

  const isAuthContent = (
    <Box display="inline-block">
      <Stack direction="row" alignItems="center">
        <IconButton onClick={handleOpenUserActionMenu} sx={{ p: 0 }}>
          <Avatar src={user?.avatar}>
            <AccountCircleIcon />
          </Avatar>
        </IconButton>
        <Menu
          sx={{ mt: '30px' }}
          id="menu-appbar"
          anchorEl={anchorElUserAction}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUserAction)}
          onClose={handleCloseUserActionMenu}
        >
          {userActions.map((action, index) => (
            <MenuItem key={index} onClick={action.handler}>
              <ListItemIconCustom>{action.icon}</ListItemIconCustom>
              <Typography variant="subtitle2" textAlign="center">
                {action.label}
              </Typography>
            </MenuItem>
          ))}
          <MenuItem onClick={handleLogout}>
            <ListItemIconCustom>
              <LogoutIcon fontSize="small" />
            </ListItemIconCustom>
            <Typography variant="subtitle2" textAlign="center">
              Đăng xuất
            </Typography>
          </MenuItem>
        </Menu>
      </Stack>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.lighter.main,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          paddingX: {
            xs: 1,
          },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: '50px !important',
          }}
        >
          {!searchOpen && (
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{
                  color: (theme) => theme.palette.lighter.main,
                  opacity: 0.6,
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <MenuIcon />
              </IconButton>

              <Drawer anchor="left" open={openNav} onClose={handleCloseNavMenu}>
                <List
                  sx={{
                    height: '100%',
                    backgroundColor: 'primary.main',
                  }}
                >
                  {content.NAV_LINK.map((page) => (
                    <ListItem key={page.text}>
                      <NavLinkStyled
                        key={page.text}
                        component={RouterLink}
                        to={page.path}
                        // onClick={handleCloseNavMenu}
                        sx={{ display: 'flex', alignItems: 'center', mr: 2 }}
                      >
                        <ListItemIcon>
                          {
                            <page.icon
                              fontSize="small"
                              sx={{ mr: 1, color: 'white' }}
                            />
                          }
                        </ListItemIcon>
                        {page.text}
                      </NavLinkStyled>
                    </ListItem>
                  ))}
                </List>
              </Drawer>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Logo />
                <NavLinkStyled
                  sx={{
                    mr: 2,
                    flexGrow: 1,
                    color: 'inherit',
                    fontSize: '18px',
                    fontFamily: '_MontserratBold',
                    mx: 1,
                  }}
                  component={RouterLink}
                  to={paths.HOME}
                >
                  {content.LOGO}
                </NavLinkStyled>
              </Stack>
            </Box>
          )}

          {/* <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} /> */}
          {!searchOpen && <ToggleThemeButton />}

          {user ? isAuthContent : notAuthAction}

          {/* Avatar */}
          {!isDesktop && !user && !searchOpen && (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleOpenLoginModal}>
                  <Typography textAlign="center">
                    {content.NAV_AUTH.login.text}
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography
                    textAlign="center"
                    onClick={handleOpenRegisterModal}
                  >
                    {content.NAV_AUTH.register.text}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
