import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Box,
  List,
  Button,
} from '@mui/material';
import { ExamPageProps } from './type';
import useAuth from '../../hooks/useAuth';
import CourseInfoItem from '../Courses/CourseInfoItem';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SubjectIcon from '@mui/icons-material/Subject';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TitleIcon from '@mui/icons-material/Title';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const ExamPage: React.FC<ExamPageProps> = ({
  exam,
  mode = 'view',
  examInput,
  setExamInput,
}) => {
  const { user } = useAuth();

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (name === 'questionURL' && files && files.length > 0) {
      const file = files[0];
      const fileURL = URL.createObjectURL(file); // Create URL for the file
      setExamInput((prevState) => ({
        ...prevState,
        questionURL: fileURL,
        questionPdf: file,
      }));
    } else {
      setExamInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Toggle executedAt field
  const toggleExecutedAt = () => {
    setExamInput((prevState) => ({
      ...prevState,
      executedAt: prevState.executedAt ? null : new Date(),
    }));
  };

  return (
    <Grid container spacing={2} sx={{ height: '100%' }}>
      {/* Left Panel */}
      <Grid
        item
        xs={12}
        md={9}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        {examInput.questionURL ? (
          <iframe
            src={examInput.questionURL + '#toolbar=0'}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title={examInput.title}
          />
        ) : (
          <Typography variant="h6" align="center" color="textSecondary">
            Chưa chọn file PDF
          </Typography>
        )}
      </Grid>

      {/* Right Panel */}
      <Grid item xs={12} md={3}>
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Exam Information
          </Typography>

          {(user?.role === 'teacher' || user?.role === 'admin') &&
          (mode === 'edit' || mode === 'create') ? (
            <>
              <Button variant="contained" component="label" sx={{ mb: 2 }}>
                Tải File PDF
                <input
                  type="file"
                  name="questionURL"
                  accept="application/pdf"
                  onChange={handleInputChange}
                  hidden
                />
              </Button>
              <TextField
                label="Title"
                name="title"
                value={examInput.title}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Duration (minutes)"
                name="duration"
                value={examInput.duration}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Subject"
                name="subject"
                value={examInput.subject}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />

              {examInput.executedAt && (
                <>
                  <DatePicker
                    sx={{ marginTop: 3 }}
                    label="Executed Date"
                    value={dayjs(examInput.executedAt)}
                    onChange={(newDate) =>
                      setExamInput((prevState) => ({
                        ...prevState,
                        executedAt: newDate ? newDate.toDate() : null,
                      }))
                    }
                  />

                  <TimePicker
                    sx={{ marginTop: 3 }}
                    label="Executed Time"
                    value={dayjs(examInput.executedAt)}
                    onChange={(newTime) =>
                      setExamInput((prevState) => ({
                        ...prevState,
                        executedAt: newTime ? newTime.toDate() : null,
                      }))
                    }
                    ampm={false}
                  />
                </>
              )}

              {/* Toggle ExecutedAt Button */}
              <Button
                variant="outlined"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={toggleExecutedAt}
              >
                {examInput.executedAt ? 'Disable Time' : 'Enable Time'}
              </Button>
            </>
          ) : (
            <Box>
              <List>
                <CourseInfoItem
                  icon={<TitleIcon color="primary" fontSize="small" />}
                  value={examInput.title}
                />
                <CourseInfoItem
                  icon={<AccessTimeIcon color="primary" fontSize="small" />}
                  value={`${examInput.duration} minutes`}
                />
                <CourseInfoItem
                  icon={<SubjectIcon color="primary" fontSize="small" />}
                  value={examInput.subject}
                />
                <CourseInfoItem
                  icon={<CalendarTodayIcon color="primary" fontSize="small" />}
                  value={
                    'Created At: ' +
                    dayjs(exam.createdAt || '').format('D/M/YYYY')
                  }
                />

                {examInput.executedAt && (
                  <>
                    <CourseInfoItem
                      icon={
                        <CalendarTodayIcon color="primary" fontSize="small" />
                      }
                      value={
                        'Executed At: ' +
                        dayjs(exam.executedAt || '').format('D/M/YYYY') +
                        ' - ' +
                        examInput.executedAt.toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false, // Use 24-hour format
                        })
                      }
                    />
                    {/* <CourseInfoItem
                      icon={<AccessTimeIcon color="primary" fontSize="small" />}
                      value={examInput.executedAt.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false, // Use 24-hour format
                      })}
                    /> */}
                  </>
                )}
              </List>
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ExamPage;
