import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Box,
  List,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import useAuth from '../../hooks/useAuth';
import CourseInfoItem from '../Courses/CourseInfoItem';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SubjectIcon from '@mui/icons-material/Subject';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TitleIcon from '@mui/icons-material/Title';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ItemPageProps } from './type';
import LinkIcon from '@mui/icons-material/Link';
import { useLocation } from 'react-router-dom';
import {
  createNewPDF,
  createNewVideo,
  fetchPDFById,
  fetchVideoById,
  updatePDFById,
  updateVideoById,
} from '../../api';
import { VideoPdfItem } from './type';
import {
  addCourseItem,
  CourseItem,
  updateCourseItem,
} from '../../redux/slices/courseItemSlice';

export const formatUrl = (url: string): string => {
  console.log('URL:', url);
  // Handle Google Drive URLs
  const driveRegex =
    /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/(view|preview)/;
  const driveMatch = url.match(driveRegex);
  if (driveMatch) {
    const fileId = driveMatch[1];
    return `https://drive.google.com/file/d/${fileId}/preview`;
  }

  // Handle shortened YouTube URLs
  const youtubeShortRegex = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]+)(\?.*)?/;
  const youtubeShortMatch = url.match(youtubeShortRegex);
  if (youtubeShortMatch) {
    const videoId = youtubeShortMatch[1];
    const queryParams = youtubeShortMatch[2] || '';
    return `https://www.youtube.com/embed/${videoId}${queryParams}`;
  }

  // Handle full YouTube URLs
  const youtubeFullRegex =
    /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)(\?.*)?/;
  const youtubeFullMatch = url.match(youtubeFullRegex);
  if (youtubeFullMatch) {
    const videoId = youtubeFullMatch[1];
    const queryParams = youtubeFullMatch[2] || '';
    return `https://www.youtube.com/embed/${videoId}${queryParams}`;
  }

  // Return original URL if it doesn't match any pattern
  return url;
};

const VideoPdfPage: React.FC<ItemPageProps> = ({
  itemId,
  mode = 'view',
  courseId = '',
  parentFolderId = '',
  closeDialog = () => {},
  type = 'video',
}) => {
  const { user } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const [itemInput, setItemInput] = useState({
    title: 'New Item',
    url: 'https://drive.google.com/file/d/1NkieMNrpDS0OeUJRFWcvXjQkOmh9_IgT/preview',
  });

  useEffect(() => {
    const loadItem = async () => {
      try {
        if (!itemId) return;

        let res;
        if (type === 'video') {
          res = await fetchVideoById(itemId);
        } else if (type === 'pdf') {
          res = await fetchPDFById(itemId);
        }

        const data = res.data;
        console.log(
          `${type.charAt(0).toUpperCase() + type.slice(1)} Data:`,
          data
        );

        setItemInput({
          title: data.title || 'Untitled',
          url: formatUrl(data.url) || '',
        });
      } catch (err) {
        console.error(`Error fetching ${type}:`, err);
      }
    };

    loadItem();
  }, [itemId, type]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setItemInput((prevState) => ({
      ...prevState,
      [name]: name === 'url' ? formatUrl(value) : value,
    }));
  };

  const handleSave = async () => {
    if (mode === 'edit') {
      // Update item
      if (type === 'video') {
        const videoPdfItem = await updateVideoById(itemId, {
          ...itemInput,
          type: 'video',
        } as VideoPdfItem);
        const courseItem = {
          ...videoPdfItem.data,
          type: 'video',
          itemId: videoPdfItem.data.videoId,
        } as CourseItem;
        console.log('Updated Video:', courseItem);
        dispatch(updateCourseItem(courseItem));
      }
      if (type === 'pdf') {
        const videoPdfItem = await updatePDFById(itemId, {
          ...itemInput,
          type: 'pdf',
        } as VideoPdfItem);
        const courseItem = {
          ...videoPdfItem.data,
          type: 'pdf',
          itemId: videoPdfItem.data.pdfId,
        } as CourseItem;
        dispatch(updateCourseItem(courseItem));
      }
    } else {
      // Create new item
      if (type === 'video') {
        const videoPdfItem = await createNewVideo(courseId, parentFolderId, {
          ...itemInput,
          type: 'video',
        } as VideoPdfItem);
        const courseItem = {
          ...videoPdfItem.data,
          type: 'video',
          itemId: videoPdfItem.data.videoId,
        } as CourseItem;
        dispatch(addCourseItem(courseItem));
      }
      if (type === 'pdf') {
        const videoPdfItem = await createNewPDF(courseId, parentFolderId, {
          ...itemInput,
          type: 'pdf',
        } as VideoPdfItem);
        const courseItem = {
          ...videoPdfItem.data,
          type: 'pdf',
          itemId: videoPdfItem.data.pdfId,
        } as CourseItem;
        dispatch(addCourseItem(courseItem));
      }
    }
    closeDialog();
  };

  const height =
    location.pathname.includes('video') || location.pathname.includes('pdf')
      ? '90vh'
      : '80vh';

  return (
    <Box sx={{ width: '100%', mt: 3, px: 3 }}>
      {/* Content */}
      <Box sx={{ mt: 3, height: height }}>
        <Grid container spacing={2} sx={{ height: { height }, mt: 3, px: 0 }}>
          {/* Left Panel */}
          <Grid
            item
            xs={12}
            md={9}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <iframe
              src={itemInput.url}
              style={{ width: '100%', height: '100%', border: 'none' }}
              title={itemInput.title}
            />
          </Grid>

          {/* Right Panel */}
          <Grid item xs={12} md={3}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 0,
              }}
            >
              <Typography variant="h6" gutterBottom>
                {type.toUpperCase()} Information
              </Typography>

              {(user?.role === 'teacher' || user?.role === 'admin') &&
              (mode === 'edit' || mode === 'create') ? (
                <>
                  <TextField
                    label="URL"
                    name="url"
                    value={itemInput.url}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    color="warning"
                    focused
                  />
                  <TextField
                    label="Title"
                    name="title"
                    value={itemInput.title}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </>
              ) : (
                <Box>
                  <List>
                    <CourseInfoItem
                      icon={<TitleIcon color="primary" fontSize="small" />}
                      value={itemInput.title}
                    />
                    <CourseInfoItem
                      icon={<LinkIcon color="primary" fontSize="small" />}
                      value={itemInput.url}
                      URL={true}
                    />
                  </List>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Save Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {(mode === 'edit' || mode === 'create') && (
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={closeDialog}
          sx={{ ml: 1 }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default VideoPdfPage;
