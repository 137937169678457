import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Stack,
  IconButton,
  ListItemIcon,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';
import { deleteCourseById } from '../../api';
import { useDispatch } from 'react-redux';
import { deleteCourse } from '../../redux/slices/courseSlice';
import { appActions } from '../../redux/slices/appSlice';
import ReportIcon from '@mui/icons-material/Report';
import CreateIcon from '@mui/icons-material/Create';
import CreateCourseForm from './CreateCourseForm';
import ConfirmDialog from './ComfirmDialog';
import CourseInfoItem from '../Courses/CourseInfoItem';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export type CourseProps = {
  courseId: string;
  image: string;
  title: string;
  description: string;
  favorites: number;
  members: number;
  exams: number;
  videos: number;
  pdfs: number;
  createdAt: string;
  lastUpdatedAt: string;
  createdBy: {
    _id: string;
    fullName: string;
    avatar: string;
  };
  price: number;
};

const CourseCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  borderRadius: 0,
  boxShadow: theme.shadows[3],
  marginTop: theme.spacing(2),
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.default,
}));

const CourseImage = styled(CardMedia)(({ theme }) => ({
  borderRadius: 5,
  width: '100%', // Full width
  paddingTop: '56.25%', // Tỷ lệ khung hình 16:9 (100 / (16 / 9))
  position: 'relative', // Định vị để cho phép con absolute
  '& img': {
    position: 'absolute', // Ảnh được định vị tuyệt đối
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Đảm bảo hình ảnh bao phủ khu vực mà vẫn giữ nguyên tỷ lệ
  },
}));

const CourseContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '&:last-child': {
    paddingBottom: 0,
  },
  marginRight: theme.spacing(2.5),
}));

const CourseTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(0.5),
  display: '-webkit-box', // Required for limiting lines of text
  WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
  WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
  overflow: 'hidden', // Hides the overflowing text
  textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
  height: 'auto', // Ensures the height adapts to content
}));

const CourseDescription = styled(Typography)(({ theme }) => ({
  display: '-webkit-box', // Required for limiting lines of text
  WebkitLineClamp: 2, // Limits to 2 lines; you can adjust this number
  WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
  overflow: 'hidden', // Hides the overflowing text
  textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
  height: 'auto', // Ensures the height adapts to content
}));

export const CourseStats = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
}));

export const ListItemIconCustom = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 'small',
}));

export const AdminActions = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(1),
}));

export const CircleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  borderRadius: '50%', // Làm nút tròn
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Course: React.FC<CourseProps> = ({
  courseId: id,
  image,
  title,
  description,
  favorites,
  members,
  exams,
  videos,
  pdfs,
  createdAt,
  lastUpdatedAt,
  createdBy,
  price = 0,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showCreateForm, setShowCreateForm] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = () => {
    navigate(`/course/${id}`);
  };

  const handleEdit = () => {
    setShowCreateForm(true);
  };

  const handleDelete = async () => {
    setLoading(true); // Bắt đầu tải

    try {
      await deleteCourseById(id);
      dispatch(deleteCourse(id)); // Gửi hành động xóa khóa học
    } catch (error) {
      console.error('Lỗi khi xóa khóa học:', error);
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Xóa khóa học thất bại',
        })
      );
    } finally {
      setLoading(false); // Dừng tải
      setOpenDialog(false); // Đóng hộp thoại
    }
  };

  const handleCloseForm = () => {
    setShowCreateForm(false);
  };

  return (
    <>
      <CourseCard onClick={handleClick}>
        <CourseImage image={image} title={title} />
        <CourseContent sx={{ position: 'relative' }}>
          <IconButton
            aria-label="more"
            aria-controls="course-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            sx={{ position: 'absolute', top: 12, right: -20 }} // Positioned at top right
            color="primary"
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>

          {/* Menu with Edit and Delete actions */}
          <Menu
            id="course-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleEdit();
                setAnchorEl(null);
              }}
            >
              <ListItemIconCustom>
                <EditIcon fontSize="small" />
              </ListItemIconCustom>
              <Typography variant="caption">Edit</Typography>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setOpenDialog(true);
                setAnchorEl(null);
              }}
            >
              <ListItemIconCustom>
                <DeleteIcon fontSize="small" />
              </ListItemIconCustom>
              <Typography variant="caption">Delete</Typography>
            </MenuItem>
          </Menu>
          <CourseTitle variant="h6">{title}</CourseTitle>
          <CourseDescription variant="subtitle1">
            {description}
          </CourseDescription>
          <Stack direction="row" spacing={1} mt={1}>
            <Avatar src={createdBy.avatar}>
              <AccountCircleIcon />
            </Avatar>
            <Stack direction="column" spacing={0}>
              <CourseStats>
                {/* <CreateIcon
              color="primary"
              fontSize="small"
              sx={{ marginRight: 1 }}
            /> */}
                <Typography
                  variant="subtitle1"
                  sx={{ color: 'text.secondary' }}
                >
                  {/* Được tạo bởi {createdBy.fullName} */}
                  {createdBy.fullName}
                </Typography>
              </CourseStats>
              <CourseStats>
                <Stack direction="row" spacing={1}>
                  {/* <CourseInfoItem
                sx={{ marginBottom: 0, color: 'text.secondary' }}
                icon={<LocalAtmIcon color="primary" fontSize="small" />}
                value={price || 'Miễn phí'}
                label={price ? 'đ' : ''}
                ml={0.5}
              />
              <CourseInfoItem
                sx={{ marginBottom: 0, color: 'text.secondary' }}
                icon={<FavoriteIcon color="primary" fontSize="small" />}
                value={favorites}
                ml={0.5}
              /> */}
                  <CourseInfoItem
                    sx={{
                      marginBottom: 0,
                      color: 'text.secondary',
                    }}
                    variant="subtitle1"
                    icon={<PeopleIcon color="primary" fontSize="small" />}
                    value={members}
                    ml={0.5}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: 'text.secondary' }}
                  >
                    {' '}
                    •{' '}
                    {formatDistanceToNow(new Date(lastUpdatedAt), {
                      addSuffix: true,
                      locale: vi, // Sử dụng tiếng Việt
                    })}
                  </Typography>
                </Stack>
              </CourseStats>
            </Stack>
          </Stack>

          <CourseStats>
            {/* <ReportIcon
              color="primary"
              fontSize="small"
              sx={{ marginRight: 1 }}
            /> */}
            {/* <Typography variant="subtitle1">
              Cập nhật lần cuối:{' '}
              {formatDistanceToNow(new Date(lastUpdatedAt), {
                addSuffix: true,
                locale: vi, // Sử dụng tiếng Việt
              })}
            </Typography> */}
          </CourseStats>

          {/* {user && (user.role === 'admin' || user.role === 'teacher') && (
            <AdminActions>
              <CircleButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                }}
              >
                <EditIcon fontSize="small" />
              </CircleButton>
              <CircleButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDialog(true); // Mở hộp thoại xác nhận
                }}
              >
                <DeleteIcon fontSize="small" />
              </CircleButton>
            </AdminActions>
          )} */}
        </CourseContent>

        <ConfirmDialog
          open={openDialog}
          onClose={(e) => {
            e.stopPropagation();
            setOpenDialog(false);
          }}
          onConfirm={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
          title="Xóa khóa học"
          content="Bạn có chắc chắn muốn xóa khóa học này không?"
          loading={loading}
        />
      </CourseCard>
      {showCreateForm && (
        <Box
          sx={{
            position: 'absolute',
            top: '60px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: '600px',
            padding: 2,
            backgroundColor: 'background.paper',
            boxShadow: 3,
            zIndex: 10,
          }}
        >
          <CreateCourseForm
            onClose={handleCloseForm}
            courseToEdit={{
              courseId: id,
              title,
              description,
              image,
              price,
            }}
          />
        </Box>
      )}
    </>
  );
};

export default Course;
