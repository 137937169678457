import * as pdfjsLib from 'pdfjs-dist/webpack';

// Load the PDF file using `pdf.js`
const loadPdf = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
  return loadingTask.promise;
};

// Utility function to find the Y-coordinate and page number of specific text in the PDF after a section title
const findTextCoordinates = async (
  file,
  sectionTitle,
  searchText,
  startPageNumber = 1
) => {
  //console.log('sectionTitle', sectionTitle, 'searchText', searchText);
  const pdfDoc = await loadPdf(file);
  const numPages = pdfDoc.numPages;

  let sectionFound = false; // Flag to indicate if the section has been found
  let sectionY = null; // Store the Y-coordinate where the sectionTitle was found
  let sectionPage = null; // Store the page number where the sectionTitle was found

  // Loop through all pages in the PDF
  for (let pageIndex = 1; pageIndex <= numPages; pageIndex++) {
    if (pageIndex < startPageNumber) {
      continue; // Skip pages before the startPage
    }
    const page = await pdfDoc.getPage(pageIndex);
    const textContent = await page.getTextContent();

    let pageText = '';
    let itemsWithPositions = [];

    // Loop through all text items in the page
    for (const item of textContent.items) {
      pageText += item.str;
      itemsWithPositions.push({
        text: item.str,
        x: item.transform[4], // x-coordinate
        y: item.transform[5], // y-coordinate
        height: item.height,
      });
    }

    if (!sectionFound) {
      // Normalize both pageText and sectionTitle to lowercase for case-insensitive comparison
      const sectionIndex = pageText
        .toLowerCase()
        .indexOf(sectionTitle.toLowerCase());

      if (sectionIndex !== -1) {
        sectionFound = true; // Section found, start searching for questions after this point
        // Find the Y-coordinate of the sectionTitle
        let accumulatedLength = 0;
        for (const item of itemsWithPositions) {
          accumulatedLength += item.text.length;
          if (accumulatedLength >= sectionIndex + sectionTitle.length) {
            sectionY = item.y;
            sectionPage = pageIndex;
            break;
          }
        }
      } else {
        continue; // Continue to the next iteration until the section is found
      }
    }

    // If the section has been found, search for the question text after the sectionTitle
    let textIndex = pageText.toLowerCase().indexOf(searchText.toLowerCase());

    while (textIndex !== -1) {
      let foundY = null;
      let accumulatedLength = 0;

      for (const item of itemsWithPositions) {
        accumulatedLength += item.text.length;

        // If the question text appears after the section title, find its position
        if (accumulatedLength >= textIndex + searchText.length) {
          if (
            sectionY !== null &&
            item.y >= sectionY &&
            pageIndex === sectionPage
          ) {
            break; // Skip if the question text is found before the sectionTitle's position
          }

          foundY = item.y + item.height;

          break;
        }
      }

      if (foundY) {
        return {
          pageNumber: pageIndex,
          yCoordinate: foundY,
        };
      } else {
        // Continue searching for the next occurrence of the question text
        textIndex = pageText
          .toLowerCase()
          .indexOf(searchText.toLowerCase(), textIndex + searchText.length);
      }
    }
  }
  console.log('not found');
  return null; // Return null if the text is not found
};

// Function to get the Y-coordinate of the last item on a specific page
const getLastItemYCoordinate = async (file, pageNumber) => {
  // Load the PDF document
  const pdfDoc = await loadPdf(file);
  const numPages = pdfDoc.numPages;

  // Check if the pageNumber is valid
  if (pageNumber < 1 || pageNumber > numPages) {
    throw new Error('Invalid page number');
  }

  // Get the page based on the provided page number
  const page = await pdfDoc.getPage(pageNumber);
  const textContent = await page.getTextContent();

  // Initialize variables to track the last (bottom) item's Y-coordinate
  let lastY = null;

  // Loop through all text items in the page
  for (const item of textContent.items) {
    const itemY = item.transform[5] - item.height / 2; // Get the Y-coordinate of the item

    // Update lastY if it's null or if the current item is lower (smaller Y value)
    if (lastY === null || itemY < lastY) {
      lastY = itemY;
    }
  }

  // Return the Y-coordinate of the last (bottom) item
  return lastY;
};

const cropPdfBetweenCoordinates = async (
  file,
  page1,
  y1,
  page2,
  y2,
  scale = 8
) => {
  const pdfDoc = await loadPdf(file);
  const numPages = pdfDoc.numPages;

  if (page1 > numPages || page2 > numPages) {
    throw new Error('Invalid page numbers');
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Get the first page's viewport
  const firstPage = await pdfDoc.getPage(page1);
  const firstPageViewport = firstPage.getViewport({ scale });

  // Set canvas size to fit multiple pages if necessary
  const cropHeight = page1 === page2 ? Math.abs(y1 - y2) * scale : y1 * scale; // Handle multi-page height
  canvas.height = cropHeight;
  canvas.width = firstPageViewport.width;

  let currentY = 0;

  // Loop through the pages to render the part between y1 and y2
  for (let pageIndex = page1; pageIndex <= page2; pageIndex++) {
    const page = await pdfDoc.getPage(pageIndex);
    const viewport = page.getViewport({ scale });

    let renderY1 = pageIndex === page1 ? y1 : 0; // Start from y1 on the first page
    let renderY2 = pageIndex === page2 ? y2 : viewport.height / scale; // End at y2 on the last page

    const renderHeight = (renderY1 - renderY2) * scale;

    context.save();
    context.translate(0, currentY);

    const renderContext = {
      canvasContext: context,
      viewport,
      transform: [1, 0, 0, 1, 0, -(viewport.height - renderY1 * scale)],
    };

    await page.render(renderContext).promise;

    currentY += renderHeight;
    context.restore();
  }

  // Convert canvas to Blob and create a File object
  const blob = await new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob), 'image/png');
  });
  const fileName = 'cropped_pdf.png';
  const newFile = new File([blob], fileName, { type: 'image/png' });

  // Create a URL for the File object
  const fileUrl = URL.createObjectURL(newFile);

  return {
    newFile,
    fileUrl,
  };
};

// // Get a full image of the PDF, merged from all pages
// const getPdfImage = async (file, scale = 6) => {
//   const pdfDoc = await loadPdf(file);
//   const numPages = pdfDoc.numPages;
//   const canvas = document.createElement('canvas');
//   const context = canvas.getContext('2d');

//   // Get the first page's viewport to determine the size
//   const firstPage = await pdfDoc.getPage(1);
//   const firstPageViewport = firstPage.getViewport({ scale });

//   // Set canvas dimensions based on the width of a single page and the combined height of all pages
//   canvas.width = firstPageViewport.width;
//   canvas.height = firstPageViewport.height * numPages;

//   // Loop through all pages in the PDF and render each to the canvas
//   for (let pageIndex = 1; pageIndex <= numPages; pageIndex++) {
//     const page = await pdfDoc.getPage(pageIndex);
//     const viewport = page.getViewport({ scale });

//     // Move the context down for each new page
//     const yOffset = (pageIndex - 1) * viewport.height;

//     // Save and translate context for each page's position
//     context.save();
//     context.translate(0, yOffset);

//     // Render the current page
//     const renderContext = {
//       canvasContext: context,
//       viewport,
//     };

//     await page.render(renderContext).promise;

//     // Restore context after rendering each page
//     context.restore();
//   }

//   // Convert the canvas to an image or Blob URL
//   return new Promise((resolve) => {
//     canvas.toBlob((blob) => {
//       const pdfImageUrl = URL.createObjectURL(blob);
//       resolve(pdfImageUrl); // This URL can be used to display/download the full PDF image
//     }, 'image/png');
//   });
// };

export {
  findTextCoordinates,
  cropPdfBetweenCoordinates,
  getLastItemYCoordinate,
};
