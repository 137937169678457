import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Box,
  List,
  Button,
  IconButton,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { defaultQuestions, ExamPageProps, Question } from './type';
import useAuth from '../../hooks/useAuth';
import CourseInfoItem from '../Courses/CourseInfoItem';
import TitleIcon from '@mui/icons-material/Title';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListIcon from '@mui/icons-material/List';
import {
  cropPdfBetweenCoordinates,
  findTextCoordinates,
  getLastItemYCoordinate,
} from './pdfUtils.js';
//import { convertToPdf } from './docxUtils';

//Custom professional icon button
const OutlinedButton: React.FC<{
  icon: React.ReactNode;
  onClick: () => void;
}> = ({ icon, onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      border: '1px solid',
      borderColor: 'primary.main',
      borderRadius: '0%',
      padding: 1,
      margin: 1,
      color: 'primary.main',
    }}
  >
    {icon}
  </IconButton>
);

const AnswerResultPage: React.FC<ExamPageProps> = ({
  exam,
  mode = 'view',
  examInput,
  setExamInput,
}) => {
  const { user } = useAuth();

  //State to handle questions
  const [questions, setQuestions] =
    React.useState<Question[]>(defaultQuestions);

  const [activeQuestion, setActiveQuestion] = React.useState<Question | null>(
    null
  );

  const [viewMode, setViewMode] = React.useState<'pdf' | 'list' | 'single'>(
    'pdf'
  );

  const [examTemplate, setExamTemplate] = React.useState<'thpt' | 'custom'>(
    'thpt'
  );

  const [activeFile, setActiveFile] = React.useState<File | null>(null);

  const [imgUrl, setImgUrl] = React.useState<string | null>(null);
  const [answerImgUrl, setAnswerImgUrl] = React.useState<string | null>(null);

  //Create new array of sections include title and questions (questions are grouped by same section)
  const sections = questions.reduce((acc, question) => {
    const sectionIndex = acc.findIndex(
      (section) => section.title === question.section
    );
    if (sectionIndex === -1) {
      acc.push({ title: question.section, questions: [question] });
    } else {
      acc[sectionIndex].questions.push(question);
    }
    return acc;
  }, [] as { title: string; questions: Question[] }[]);

  useEffect(() => {
    console.log('exam.questions:', exam.questions);
    if (exam.questions && exam.questions.length > 0) {
      setQuestions(exam.questions);
      setViewMode('pdf');
    } else {
      if (examTemplate === 'thpt') {
        setQuestions(defaultQuestions);
      } else if (examTemplate === 'custom') {
        console.log('Custom template');
        setQuestions([]);
      }
    }
  }, [exam.questions, examTemplate]);

  const showSingleQuestion = (question: Question) => {
    //setActiveQuestion(question);
    setViewMode('single');
    setImgUrl(question.questionUrl);
    setAnswerImgUrl(question.answerUrl);
  };

  const getImageWithStartPage = async (
    question: Question,
    index: number,
    nextQuestion: Question,
    nextIndex: number,
    startPage: number
  ) => {
    let start;
    let end;
    // Find the Y-coordinate and page number for the start of the current question
    start = await findTextCoordinates(
      activeFile,
      question.section + '.',
      'Câu ' + index,
      startPage
    );

    if (!start) {
      console.error('Failed to find coordinates for the question.');
      return;
    }

    // Find the Y-coordinate and page number for the start of the next question (or end of the current one)
    if (nextQuestion) {
      if (nextQuestion.section === question.section) {
        end = await findTextCoordinates(
          activeFile,
          nextQuestion.section + '.',
          'Câu ' + nextIndex + ':',
          startPage
        );
      } else {
        end = await findTextCoordinates(
          activeFile,
          question.section + '.',
          nextQuestion.section + '.',
          startPage
        );
      }
      if (!end || end.pageNumber > start.pageNumber) {
        const newEndCoordinate = await getLastItemYCoordinate(
          activeFile,
          start.pageNumber
        );
        end = { pageNumber: start.pageNumber, yCoordinate: newEndCoordinate };
      }
    } else {
      end = await getLastItemYCoordinate(activeFile, start.pageNumber);
      end = { pageNumber: start.pageNumber, yCoordinate: end };
    }

    // Check if both `start` and `end` coordinates were found
    if (start && end) {
      // Crop the PDF between the found coordinates
      const image = await cropPdfBetweenCoordinates(
        activeFile,
        start.pageNumber,
        start.yCoordinate,
        end.pageNumber,
        end.yCoordinate
      );
      return image;
      // Set the image URL to display the cropped image
    } else {
      console.error('Failed to find coordinates for the question.');
    }
  };

  const showListQuestions = async () => {
    setViewMode('list');
  };

  useEffect(() => {
    const generateImages = async () => {
      if (activeFile) {
        console.log('Generating images...');
        //Reset the questions by adding the image URL to each question
        // Create a new image for answer from the PDF file
        const lastQuestionIndex = questions.length - 1;
        const lastQuestion = questions[lastQuestionIndex];
        let last = await findTextCoordinates(
          activeFile,
          lastQuestion.section + '.',
          'Câu ' + lastQuestion.index
        );

        if (!last) {
          console.error('Failed to find coordinates for the question.');
          return;
        }

        let lastPage = last.pageNumber + 1;

        const newQuestions = await Promise.all(
          questions.map(async (question, index) => {
            const nextQuestion = questions[index + 1] ?? null;
            const image = await getImageWithStartPage(
              question,
              question.index,
              nextQuestion,
              nextQuestion?.index ?? 1,
              1
            );
            if (!image) {
              console.error('Failed to generate image for question:', question);
              return question;
            }
            const answerImage = await getImageWithStartPage(
              question,
              question.index,
              nextQuestion,
              nextQuestion?.index ?? 1,
              lastPage
            );
            if (!answerImage) {
              console.error('Failed to generate image for question:', question);
              return question;
            }
            return {
              ...question,
              questionUrl: image?.fileUrl,
              answerUrl: answerImage?.fileUrl,
              questionFile: image?.newFile,
              answerFile: answerImage?.newFile,
            };
          })
        );
        setQuestions(newQuestions);
        setExamInput((prevState) => ({
          ...prevState,
          questions: newQuestions,
        }));
      }
    };

    // Trigger the image generation when a new activeFile is input
    if (activeFile) {
      generateImages();
    }
  }, [activeFile]);

  // Assuming setListImage and setListAnswerImage are defined in the component's state

  // Handle input changes
  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (name === 'answerURL' && files && files.length > 0) {
      const file = files[0];
      const fileURL = URL.createObjectURL(file); // Create URL for the file

      setExamInput((prevState) => ({
        ...prevState,
        answerURL: fileURL,
        answerPdf: file,
      }));

      setActiveFile(file);
      setViewMode('list');
    } else {
      setExamInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Grid container spacing={2} sx={{ height: '100%' }}>
      {/* Left Panel */}
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxHeight: '100%',
        }}
      >
        {viewMode === 'pdf' &&
          (examInput.answerURL ? (
            <iframe
              src={examInput.answerURL + '#toolbar=0'}
              style={{ width: '100%', height: '100%', border: 'none' }}
              title={examInput.title}
            />
          ) : (
            <Typography variant="h6" align="center" color="textSecondsary">
              Chưa chọn file PDF
            </Typography>
          ))}

        {viewMode === 'list' && (
          <Stack
            direction="column"
            spacing={2}
            style={{ width: '100%', maxHeight: '100%', overflow: 'auto' }}
          >
            {questions.map((question, index) => (
              <div key={index}>
                <Typography variant="h6" gutterBottom>
                  Câu hỏi
                </Typography>
                <img
                  src={question.questionUrl || ''}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    border: 'none',
                  }}
                  title={examInput.title}
                ></img>
                <Typography variant="h6" gutterBottom>
                  Đáp án
                </Typography>
                <img
                  src={question.answerUrl || ''}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    border: 'none',
                  }}
                  title={examInput.title}
                ></img>
              </div>
            ))}
          </Stack>
        )}

        {viewMode === 'single' && (
          <>
            <Stack
              spacing={2}
              direction="column"
              sx={{ width: '100%', maxHeight: '100%', overflow: 'auto' }}
            >
              <Typography variant="h6" gutterBottom>
                Câu hỏi
              </Typography>
              <img
                src={imgUrl || ''}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  border: 'none',
                }}
                title={examInput.title}
              ></img>
              <Typography variant="h6" gutterBottom>
                Đáp án
              </Typography>
              <img
                src={answerImgUrl || ''}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                  border: 'none',
                }}
                title={examInput.title}
              ></img>
            </Stack>
          </>
        )}
      </Grid>

      {/* Right Panel */}
      <Grid item xs={12} md={3}>
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 0,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Thông tin đáp án
          </Typography>
          {(user?.role === 'teacher' || user?.role === 'admin') &&
          (mode === 'edit' || mode === 'create') ? (
            <>
              <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel id="demo-simple-select-label">Mẫu đề</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={examTemplate}
                  label="examTemplate"
                  onChange={(e) =>
                    setExamTemplate(e.target.value as 'thpt' | 'custom')
                  }
                >
                  <MenuItem value="thpt">Đề thi THPT</MenuItem>
                  <MenuItem value="custom">Mẫu tự do</MenuItem>
                </Select>
              </FormControl>

              <Button variant="contained" component="label" sx={{ mb: 2 }}>
                Tải file PDF
                <input
                  type="file"
                  name="answerURL"
                  accept="application/pdf"
                  onChange={handleInputChange}
                  hidden
                />
              </Button>
            </>
          ) : (
            <Box>
              <List>
                <CourseInfoItem
                  icon={<TitleIcon color="primary" fontSize="small" />}
                  value={examInput.title}
                />
              </List>
            </Box>
          )}

          <Box sx={{ marginTop: 1 }}>
            {/* Button for pdf*/}
            <OutlinedButton
              icon={<PictureAsPdfIcon />}
              onClick={() => setViewMode('pdf')}
            ></OutlinedButton>
            {/* Button for list*/}
            <OutlinedButton
              icon={<ListIcon />}
              onClick={() => showListQuestions()}
            ></OutlinedButton>

            {/* Section for questions */}
            <Box sx={{ marginTop: 2 }}>
              {sections.map((section, sectionIndex) => (
                <Paper
                  elevation={3}
                  sx={{ p: 2, mb: 2, border: '1px ' }}
                  key={`section-${sectionIndex}`} // Unique key for section
                >
                  <Typography variant="subtitle2" gutterBottom>
                    {section.title}
                  </Typography>
                  {section.questions.map((question, questionIndex) => (
                    <Button
                      variant="outlined"
                      sx={{ m: 0.1 }}
                      key={`question-${sectionIndex}-${questionIndex}`} // Unique key for each question
                      onClick={() => {
                        showSingleQuestion(question);
                      }}
                    >
                      {questionIndex + 1 + '.'} {' ' + question.correctAnswer}
                    </Button>
                  ))}
                </Paper>
              ))}
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AnswerResultPage;
