import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../pages/Layout';
import paths from '../constants/paths';
import HomePage from '../pages/Home';
import NotFoundPage from '../pages/Error/NotFoundPage';
import CoursesPage from '../pages/Courses';
import ExamStepper from '../pages/Exams';

export default function MyRouter() {
  return (
    <Layout>
      <Routes>
        <Route path={paths.HOME} element={<HomePage />} />
        <Route path={paths.COURSE} element={<CoursesPage />} />
        <Route path={paths.EXAM} element={<ExamStepper />} />
        <Route path={paths.ANSWER} element={<ExamStepper />} />
        <Route path={paths.LEADERBOARD} element={<ExamStepper />} />
        {/* <Route path={paths.BIO} element={<BioLinkPage />} />
        <Route path={paths.EXAM_SHEET} element={<AnswerSheetPage />} />
        <Route path={paths.ANSWER_SHEET} element={<AnswerSheetPage />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  );
}
