import React from 'react';
import {
  Dialog,
  Paper,
  Typography,
  InputAdornment,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CakeIcon from '@mui/icons-material/Cake';
import SchoolIcon from '@mui/icons-material/School';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

// RHF
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Form Component
import RHFTextField from '../RHF/RHFTextField';
import FormProvider from '../RHF/FormProvider';

// Data
import { signup } from '../../api';
import { SignUpData } from '../../model/Student';

// Redux
import { appActions } from '../../redux/slices/appSlice';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { authActions } from '../../redux/slices/authSlice';

const RegisterModal = () => {
  const open = useAppSelector((state) => state.app.showRegisterModal);
  const dispatch = useAppDispatch();
  const schema = yup.object().shape({
    username: yup.string().required('Vui lòng nhập tên người dùng'),
    name: yup.string().required('Vui lòng nhập họ và tên'),
    password: yup
      .string()
      .min(6, 'Mật khẩu phải ít nhất 6 ký tự')
      .required('Vui lòng nhập mật khẩu'),
    school: yup.string().required('Vui lòng nhập trường học'),
    email: yup
      .string()
      .email('Vui lòng nhập email hợp lệ')
      .required('Vui lòng nhập email'),
  });
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      name: '',
      password: '',
      school: '',
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    dispatch(appActions.toggleShowRegisterModal());
  };

  const handleSubmitForm: SubmitHandler<SignUpData> = async (
    data: SignUpData
  ) => {
    try {
      const { data: response } = await signup(data);
      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: 'Tạo tài khoản thành công!',
        })
      );
      dispatch(
        authActions.setUser({
          user: response.data.user,
        })
      );
      dispatch(appActions.toggleShowRegisterModal());
    } catch (err: any) {
      // Extract the error message from the server's response
      const errorMessage =
        err.response?.data?.message || 'Tạo tài khoản thất bại!';
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: errorMessage,
        })
      );
      console.log(err);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper sx={{ padding: 3, maxWidth: 360 }}>
        <Typography
          fontSize="30px"
          fontFamily="_SegoeUIBold"
          fontWeight="bold"
          textAlign="center"
          mb={3}
        >
          Sign Up
        </Typography>
        <FormProvider<SignUpData> methods={methods} handler={handleSubmitForm}>
          <RHFTextField
            name="username"
            label="Tên người dùng"
            startAdornment={
              <InputAdornment position="start">
                <PersonOutlinedIcon
                  fontSize="small"
                  sx={{ color: 'text.primary' }}
                />
              </InputAdornment>
            }
            placeholder="Nhập tên người dùng"
          />
          <RHFTextField
            name="password"
            label="Mật khẩu"
            startAdornment={
              <InputAdornment position="start">
                <LockOutlinedIcon sx={{ color: 'text.primary' }} />
              </InputAdornment>
            }
            placeholder="Nhập mật khẩu"
            type="password"
          />
          <RHFTextField
            name="name"
            label="Họ và tên"
            startAdornment={
              <InputAdornment position="start">
                <PersonOutlinedIcon
                  fontSize="small"
                  sx={{ color: 'text.primary' }}
                />
              </InputAdornment>
            }
            placeholder="Nhập họ và tên"
          />
          <RHFTextField
            name="email"
            label="Email"
            startAdornment={
              <InputAdornment position="start">
                <MailOutlineIcon
                  fontSize="small"
                  sx={{ color: 'text.primary' }}
                />
              </InputAdornment>
            }
            placeholder="Nhập email"
          />
          <RHFTextField
            name="school"
            label="Trường học"
            startAdornment={
              <InputAdornment position="start">
                <SchoolIcon fontSize="small" sx={{ color: 'text.primary' }} />
              </InputAdornment>
            }
            placeholder="Nhập trường học"
          />

          <Stack mt={4} alignItems="center">
            <Button
              variant="contained"
              sx={{ p: 1.25, width: '100%', fontSize: '12px' }}
              type="submit"
            >
              Đăng ký
            </Button>
          </Stack>
        </FormProvider>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Paper>
    </Dialog>
  );
};

export default RegisterModal;
