import React, { useState } from 'react';
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  Box,
  IconButton,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CourseInfoItem from './CourseInfoItem';
import LinkIcon from '@mui/icons-material/Link';
import { CircleButton } from '../Home/Course';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

interface CourseInfoSectionProps {
  courseContent: any;
  user: any;
  onAddNewLink: (newLink: string) => void;
  onAddNewContactLink: (newLink: string) => void;
  onDeleteLink: (link: string) => void;
  onAddNewLearningObjective: (newObjective: string) => void;
  onDeleteLearningObjective: (objective: string) => void;
}

const CourseInfoSection: React.FC<CourseInfoSectionProps> = ({
  courseContent,
  user,
  onAddNewLink,
  onAddNewContactLink,
  onDeleteLink,
  onAddNewLearningObjective,
  onDeleteLearningObjective,
}) => {
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [isAddingContactLink, setIsAddingContactLink] = useState(false);
  const [newLink, setNewLink] = useState('');
  const [isAddingLearningObjective, setIsAddingLearningObjective] =
    useState(false);

  const handleLinkSubmit = () => {
    if (newLink.trim()) {
      onAddNewLink?.(newLink.trim());
    }
    setIsAddingLink(false);
    setNewLink('');
  };

  const handleContactLinkSubmit = () => {
    if (newLink.trim()) {
      onAddNewContactLink?.(newLink.trim());
    }
    setIsAddingContactLink(false);
    setNewLink('');
  };

  const handleLearningObjectiveSubmit = () => {
    if (newLink.trim()) {
      onAddNewLearningObjective?.(newLink.trim());
    }
    setIsAddingLearningObjective(false);
    setNewLink('');
  };

  return (
    <>
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        <Typography variant="h6" gutterBottom>
          Những gì bạn sẽ học
        </Typography>

        {courseContent.learningObjectives.map(
          (objective: string, index: number) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              mb={0}
            >
              <CourseInfoItem
                icon={<CheckIcon color="primary" fontSize="small" />}
                value={objective}
                sx={{ color: 'text.primary', marginBottom: 0 }}
              />
              {(user?.role === 'teacher' || user?.role === 'admin') && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => onDeleteLearningObjective(objective)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          )
        )}

        {(user?.role === 'teacher' || user?.role === 'admin') &&
          (isAddingLearningObjective ? (
            <TextField
              autoFocus
              fullWidth
              placeholder="Nhập mục tiêu học tập mới"
              value={newLink}
              onChange={(e) => setNewLink(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleLearningObjectiveSubmit();
                if (e.key === 'Escape') setIsAddingLearningObjective(false);
              }}
              onBlur={() => setIsAddingLearningObjective(false)}
            />
          ) : (
            <Button
              variant="text"
              color="primary"
              onClick={() => setIsAddingLearningObjective(true)}
              sx={{ padding: 0, textTransform: 'none', display: 'flex' }}
            >
              + Thêm mục tiêu học tập mới
            </Button>
          ))}
      </Paper>
      <Typography variant="h6" gutterBottom>
        Thông tin khóa học
      </Typography>
      <CourseInfoItem
        icon={<LocalAtmIcon color="primary" fontSize="small" />}
        value={courseContent.price || 'Miễn phí'}
        label={courseContent.price ? 'đ' : ''}
      />
      <CourseInfoItem
        icon={<FavoriteIcon color="primary" fontSize="small" />}
        value={courseContent.favorites}
        label="Yêu thích"
      />
      <CourseInfoItem
        icon={<PeopleIcon color="primary" fontSize="small" />}
        value={courseContent.members}
        label="Thành viên"
      />
      <CourseInfoItem
        icon={<DescriptionIcon color="primary" fontSize="small" />}
        value={courseContent.exams}
        label="Bài kiểm tra"
      />
      <CourseInfoItem
        icon={<YouTubeIcon color="primary" fontSize="small" />}
        value={courseContent.videos}
        label="Video"
      />
      <CourseInfoItem
        icon={<PictureAsPdfIcon color="primary" fontSize="small" />}
        value={courseContent.pdfs}
        label="PDF"
      />
      {courseContent.links &&
        courseContent.links.map((link: string, index: number) => (
          <Box key={index} display="flex" justifyContent="space-between" mb={0}>
            <CourseInfoItem
              key={index} // Đảm bảo mỗi mục trong danh sách có một khóa duy nhất
              icon={<LinkIcon color="primary" fontSize="small" />}
              value={link}
              URL={true}
            />
            {(user?.role === 'teacher' || user?.role === 'admin') && (
              <IconButton
                size="small"
                color="primary"
                onClick={() => onDeleteLink(link)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        ))}
      {(user?.role === 'teacher' || user?.role === 'admin') &&
        (isAddingLink ? (
          <TextField
            autoFocus
            fullWidth
            placeholder="Nhập liên kết mới"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleLinkSubmit();
              if (e.key === 'Escape') setIsAddingLink(false);
            }}
            onBlur={() => setIsAddingLink(false)}
          />
        ) : (
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setIsAddingLink(true);
            }}
            sx={{ padding: 0, textTransform: 'none', display: 'flex' }}
          >
            + Thêm liên kết mới
          </Button>
        ))}
      {courseContent.contactLink && (
        <CourseInfoItem
          icon={<LinkIcon color="primary" fontSize="small" />}
          value={courseContent.contactLink}
          URL={true}
        />
      )}

      {(user?.role === 'teacher' || user?.role === 'admin') &&
        (isAddingContactLink ? (
          <TextField
            autoFocus
            fullWidth
            placeholder="Nhập liên kết liên hệ"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleContactLinkSubmit();
              if (e.key === 'Escape') setIsAddingContactLink(false);
            }}
            onBlur={() => setIsAddingContactLink(false)}
          />
        ) : (
          <Button
            variant="text"
            color="primary"
            onClick={() => setIsAddingContactLink(true)}
            sx={{ padding: 0, textTransform: 'none', display: 'flex' }}
          >
            + Thay đổi liên kết liên hệ
          </Button>
        ))}
    </>
  );
};

export default CourseInfoSection;
