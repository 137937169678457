import React, { useEffect, useState } from 'react';
import { alpha, Box, Button, useTheme, CircularProgress } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Person } from '@mui/icons-material';
import {
  addBuyCourse,
  addFavorite,
  fetchBuyCourses,
  fetchFavorites,
  removeBuyCourse,
  removeFavorite,
} from '../../api';
import { StudentInfo } from '../../model/Student';
import { useDispatch } from 'react-redux';
import { appActions } from '../../redux/slices/appSlice';
import {
  addCourseToFavorite,
  beCourseMember,
  removeCourseFromFavorite,
  unBeCourseMember,
} from '../../redux/slices/courseItemSlice';

type CourseActionsProps = {
  user: StudentInfo | null;
  courseId: string;
  contactLink?: string;
  price: number;
};

const CourseActions: React.FC<CourseActionsProps> = ({
  user,
  courseId,
  contactLink,
  price,
}) => {
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const [isBuyLoading, setIsBuyLoading] = useState(false);

  const onFavoriteClick = async () => {
    setIsFavoriteLoading(true); // Start loading for favorite button
    if (!user || !user._id) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn cần đăng nhập để lưu thông tin khóa học',
        })
      );
      setIsFavoriteLoading(false);
      return;
    }

    try {
      if (!isFavorite) {
        // Add favorite
        await addFavorite(user._id, courseId);
        dispatch(addCourseToFavorite());
      } else {
        // Remove favorite
        await removeFavorite(user._id, courseId);
        dispatch(removeCourseFromFavorite());
      }
      setIsFavorite((prev) => !prev);
    } catch (err) {
      console.error('Error updating favorite status:', err);
    } finally {
      setTimeout(() => setIsFavoriteLoading(false), 500); // Delay for at least 0.5 seconds
    }
  };

  const onBuyCourseClick = async () => {
    setIsBuyLoading(true); // Start loading for buy course button
    if (!user || !user._id) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn cần đăng nhập để mua khóa học',
        })
      );
      setIsBuyLoading(false);
      return;
    }

    if (isMember) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn đã mua khóa học này rồi',
        })
      );
      setIsBuyLoading(false);
      return;
    } else if (!isMember && price > 0) {
      dispatch(
        appActions.showLinkNotification({
          message: 'Liên hệ với giảng viên để mua khóa học: ',
          link: contactLink || '',
        })
      );
      setIsBuyLoading(false);
      return;
    }

    try {
      if (!isMember) {
        await addBuyCourse(user._id, courseId);
        dispatch(beCourseMember());
      } else {
        await removeBuyCourse(user._id, courseId);
        dispatch(unBeCourseMember());
        dispatch(
          appActions.showNotification({
            message: 'Mua khóa học thành công',
          })
        );
      }
      setIsMember((prev) => !prev);
    } catch (err) {
      console.error('Error updating course membership:', err);
    } finally {
      setTimeout(() => setIsBuyLoading(false), 500); // Delay for at least 0.5 seconds
    }
  };

  useEffect(() => {
    const loadCourseStatus = async () => {
      if (!user || !user._id) return;
      try {
        const favoriteData = await fetchFavorites(user._id, courseId);
        setIsFavorite(favoriteData.isFavorite);

        const buyCourseData = await fetchBuyCourses(user._id, courseId);
        setIsMember(buyCourseData.isBuyCourse);
      } catch (err) {
        console.error('Error fetching course data:', err);
      }
    };

    loadCourseStatus();
  }, [user, courseId]);

  const theme = useTheme();
  const prizeFirstColor = theme.palette.prize?.first || '#000';

  return (
    <Box mt={2}>
      <Button
        variant="outlined"
        fullWidth
        sx={{
          borderRadius: 0,
          mb: 1,
          color: 'background.darker',
        }}
        onClick={onFavoriteClick}
        startIcon={
          isFavoriteLoading ? (
            <CircularProgress size={20} />
          ) : isFavorite ? (
            <FavoriteIcon />
          ) : (
            <FavoriteBorderIcon />
          )
        }
        disabled={isFavoriteLoading}
      >
        {isFavorite ? 'Đã thích' : 'Thêm vào yêu thích'}
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          borderRadius: 0,
          backgroundColor: 'prize.first',
          ':hover': {
            backgroundColor: alpha(prizeFirstColor, 0.7),
          },
        }}
        onClick={onBuyCourseClick}
        startIcon={
          isBuyLoading ? (
            <CircularProgress size={20} />
          ) : isMember ? (
            <Person />
          ) : (
            <PersonAddAltOutlinedIcon />
          )
        }
        disabled={isBuyLoading}
      >
        {isMember ? 'Đã mua khoá' : 'Mua khóa học'}
      </Button>
    </Box>
  );
};

export default CourseActions;
