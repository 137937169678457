import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
} from '@mui/material';
import { addCourse, updateCourseById } from '../../api';
import { Cancel } from '@mui/icons-material'; // Import biểu tượng hủy
import { useDispatch } from 'react-redux';
import { addCourseAction, updateCourse } from '../../redux/slices/courseSlice';
import useAuth from '../../hooks/useAuth';

interface CreateCourseFormProps {
  onClose: () => void;
  courseToEdit?: {
    courseId: string;
    title: string;
    description: string;
    image: string; // URL cho ảnh xem trước
    price: number;
  };
}

const CreateCourseForm: React.FC<CreateCourseFormProps> = ({
  onClose,
  courseToEdit,
}) => {
  const dispatch = useDispatch();
  // Lấy studentId hiện tại từ Redux store hoặc context người dùng
  const { user } = useAuth();

  const [courseData, setCourseData] = useState({
    courseId: courseToEdit?.courseId || '',
    title: courseToEdit?.title || '',
    description: courseToEdit?.description || '',
    image: null as File | null, // File cho ảnh
    imageUrl: courseToEdit?.image || '', // URL cho xem trước ảnh
    price: courseToEdit?.price || 0,
  });

  const [loading, setLoading] = useState(false); // Trạng thái loading

  useEffect(() => {
    if (courseToEdit) {
      setCourseData({
        courseId: courseToEdit.courseId,
        title: courseToEdit.title,
        description: courseToEdit.description,
        image: null,
        imageUrl: courseToEdit.image,
        price: courseToEdit.price,
      });
    }
  }, [courseToEdit]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target;

    if (type === 'file' && files && files[0]) {
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      setCourseData((prevState) => ({
        ...prevState,
        image: file,
        imageUrl,
      }));
    } else {
      setCourseData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('courseId', courseData.courseId);
      formData.append('title', courseData.title);
      formData.append('description', courseData.description);
      formData.append('price', courseData.price.toString());
      if (courseData.image) {
        formData.append('image', courseData.image);
      }
      formData.append('createdBy', user?._id || ''); // Thêm studentId vào form data

      if (courseToEdit) {
        // Cập nhật khóa học
        const newCourse = await updateCourseById(courseData.courseId, formData);
        // Dispatch hành động updateCourse
        dispatch(updateCourse(newCourse));
      } else {
        // Tạo khóa học mới
        const newCourse = await addCourse(formData);
        const newCourseWithUser = { ...newCourse, createdBy: user };
        dispatch(addCourseAction(newCourseWithUser)); // Dispatch hành động addCourse
      }

      onClose(); // Đóng form
    } catch (error) {
      console.error('Lỗi khi lưu khóa học:', error);
      // Optional: đặt trạng thái lỗi và hiển thị thông báo cho người dùng
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        maxWidth: 800,
        mx: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 3,
        borderRadius: 2,
        position: 'relative',
      }}
    >
      <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
        {courseToEdit ? 'Sửa Khóa Học' : 'Tạo Khóa Học Mới'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Mã Khóa Học"
          name="courseId"
          value={courseData.courseId}
          onChange={handleChange}
          required
          //Add uppercase
          inputProps={{ style: { textTransform: 'uppercase' } }}
          sx={{ mb: 2 }}
          disabled={courseToEdit !== undefined} // Vô hiệu hóa input cho khóa học đã có
        />

        <TextField
          fullWidth
          label="Tên Khóa Học"
          name="title"
          value={courseData.title}
          onChange={handleChange}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Mô Tả"
          name="description"
          value={courseData.description}
          onChange={handleChange}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Giá"
          name="price"
          value={courseData.price || 0}
          onChange={handleChange}
          required
          //Set default value to 0
          inputProps={{ min: 0 }}
          sx={{ mb: 2 }}
        />
        <Stack direction="column" sx={{ mb: 2, textAlign: 'center' }}>
          {courseData.imageUrl && (
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                width: '100%',
                maxWidth: '100%',
                height: 'auto',
                maxHeight: '150px',
                overflow: 'hidden',
              }}
            >
              <img
                src={courseData.imageUrl}
                alt="Đã chọn"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
              <IconButton
                onClick={() =>
                  setCourseData({ ...courseData, image: null, imageUrl: '' })
                }
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bgcolor: 'background.paper',
                }}
              >
                <Cancel />
              </IconButton>
            </Box>
          )}
          <Button variant="contained" component="label" sx={{ mb: 2 }}>
            Tải Ảnh Lên
            <input type="file" name="image" onChange={handleChange} hidden />
          </Button>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mr: 2 }}
          disabled={loading} // Vô hiệu hóa nút khi đang tải
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : courseToEdit ? (
            'Cập Nhật Khóa Học'
          ) : (
            'Tạo Khóa Học'
          )}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={loading}
        >
          Hủy
        </Button>
      </form>
    </Box>
  );
};

export default CreateCourseForm;
