import React from 'react';
import { Box, Typography } from '@mui/material';

type CourseInfoItemProps = {
  icon: React.ReactNode;
  value: string | number;
  label?: string;
  URL?: boolean; // New prop to determine if value should be a link
  sx?: React.CSSProperties;
  ml?: number;
  variant?: 'subtitle1' | 'subtitle2';
};

const CourseInfoItem: React.FC<CourseInfoItemProps> = ({
  icon,
  value,
  label,
  URL = false, // Default to false
  sx,
  ml = 1,
  variant = 'subtitle2',
}) => {
  // Convert value to string for href attribute if URL is true
  const href = URL ? (typeof value === 'string' ? value : '') : undefined;

  return (
    <Box
      display="flex"
      alignItems="center"
      mb={1}
      sx={{ maxWidth: '100%', ...sx }}
    >
      {icon}
      {URL ? (
        <Typography
          variant={variant}
          ml={ml}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1,
            textDecoration: 'underline', // Underline to indicate a link
          }}
          component="a"
          href={href}
          target="_blank" // Open in a new tab
          rel="noopener noreferrer" // Security measure
        >
          {value} {label || ''}
        </Typography>
      ) : (
        <Typography
          variant={variant}
          ml={ml}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1,
          }}
        >
          {value} {label || ''}
        </Typography>
      )}
    </Box>
  );
};

export default CourseInfoItem;
